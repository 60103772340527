import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { DbService } from 'src/app/services/db.service';
import { LoadingService } from 'src/app/services/loading.service';
import { take } from 'rxjs/operators';
import { Users } from 'src/app/models/users.model';
import { PushService } from 'src/app/services/push.service';
import { SocialService } from 'src/app/services/social.service';
import * as firebase from 'firebase';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  email = '';
  pw = '';
  showPw = false;

  constructor(
    public auth: AuthService,
    private navc: NavController,
    private alertService: AlertService,
    private alertCtrl: AlertController,
    private loader: LoadingService,
    private db: DbService,
    private push: PushService,
    private social: SocialService
  ) {}

  ngOnInit() {}

  // 이메일 형식 체크
  chkEmail() {
    const regExp = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
    return this.email && regExp.test(this.email);
  }

  // 비밀번호 형식 체크
  chkPw() {
    // const regExp =
    //   /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"])[a-zA-Z\d\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]{8,16}$/;
    // return this.pw && regExp.test(this.pw);
    return true;
  }

  // 로그인
  async login() {
    await this.loader.show();
    await this.auth
      .loginUser(this.email, this.pw)
      .then(async uid => {
        await this.db
          .doc$(`users/${uid}`)
          .pipe(take(1))
          .toPromise()
          .then((user: Users) => {
            if (user.loginType[0] === 'email' && !firebase.default.auth().currentUser.emailVerified) {
              this.alertService
                .cancelOkBtn('', '이메일 인증 후 로그인이 가능합니다.<br />해당 이메일로 이메일 인증 메일을<br />전송하시겠습니까?')
                .then(async res => {
                  if (res) {
                    await this.loader.show();
                    try {
                      await firebase.default.auth().currentUser.sendEmailVerification();
                      this.alertService.okBtn(
                        '',
                        '해당 이메일로 이메일 인증 메일이<br />전송되었습니다. 메일이 오지 않았다면<br />스팸 메일함을 확인해 주세요.'
                      );
                    } catch {
                      this.alertService.okBtn('', '이메일 인증 메일 전송을<br />실패하였습니다.<br />잠시 후 다시 시도해 주세요.');
                    }
                    this.loader.hide();
                  }
                  this.auth.logoutUser();
                });
              return false;
            }
            if (!user.suspendSwitch) {
              this.push.getId().then(data => {
                if (data && !user.fixerSwitch && !user.pushId.includes(data.userId)) {
                  user.pushId.push(data.userId);
                  this.db.updateAt(`users/${uid}`, { pushId: user.pushId });
                }
              });
              this.alertService.toast('로그인 했어요.');
              this.navc.navigateForward('/tabs/home', { animated: false });
            }
          });
      })
      .catch(err => {
        if (err.code === 'auth/user-not-found' || err.code === 'auth/wrong-password') {
          this.alertService.okBtn('', '아이디 또는 비밀번호가 틀렸습니다.<br>확인 후 다시 입력해 주세요.');
        } else {
          this.alertService.showErrorMessage(err.code);
        }
      });
    this.loader.hide();
  }

  // 회원가입
  join() {
    this.navc.navigateForward('tabs/login/join', { animated: false });
  }

  // 비밀번호 재설정
  async resetPw() {
    const alert = await this.alertCtrl.create({
      cssClass: 'prompt',
      header: '비밀번호 재설정',
      message: '비밀번호 재설정을 위해<br>가입하신 이메일 주소를 입력해 주세요.',
      inputs: [
        {
          type: 'email',
        },
      ],
      buttons: [
        {
          text: '취소',
          role: 'cancel',
        },
        {
          text: '확인',
          role: 'ok',
          handler: async value => {
            const email = value[0].trim();
            if (email) {
              await this.loader.show();
              await this.db
                .collection$('users', (ref: any) => ref.where('email', '==', email).where('exitSwitch', '==', false))
                .pipe(take(1))
                .toPromise()
                .then(async user => {
                  if (user.length > 0) {
                    await this.auth
                      .resetPw(email)
                      .then(() => {
                        this.alertService.okBtn('', `${email}로 비밀번호 재설정 메일이 발송되었습니다.`);
                      })
                      .catch(err => {
                        this.alertService.showErrorMessage(err.code);
                      });
                  } else {
                    this.alertService.okBtn('', '가입된 이메일 주소가 아닙니다.<br>확인 후 다시 입력해 주세요.');
                  }
                });
              this.loader.hide();
            } else {
              this.alertService.okBtn('', '이메일 주소를 입력해 주세요.').then(() => {
                this.resetPw();
              });
            }
          },
        },
      ],
    });
    await alert.present();
  }

  // 개인정보
  goInfo() {
    this.navc.navigateForward('/personal-info', { animated: false });
  }

  // 이용약관
  goService() {
    this.navc.navigateForward('/service', { animated: false });
  }

  // 구글 로그인
  googleLogin() {
    this.social.googleLogin();
  }

  // 애플 로그인
  appleLogin() {
    this.social.appleLogin();
  }

  // 네이버 로그인
  naverLogin() {
    this.social.naverLogin();
  }

  // 카카오 로그인
  kakaoLogin() {
    this.social.kakaoLogin();
  }
}
