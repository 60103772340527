import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-request-form-info',
  templateUrl: './request-form-info.component.html',
  styleUrls: ['./request-form-info.component.scss'],
})
export class RequestFormInfoComponent implements OnInit {
  constructor(private matdialog: MatDialogRef<RequestFormInfoComponent>) {}

  ngOnInit() {}

  // 다이얼로그 닫기
  done() {
    this.matdialog.close();
  }
}
