import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Symptom } from '../models/symptom.model';
import { AlertService } from './alert.service';
import { DbService } from './db.service';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  changeRepair = new BehaviorSubject(false);

  constructor(private alert: AlertService, private navCtrl: NavController, private router: Router, private db: DbService) {}

  /** 로그인을 하지 않았을 경우 */
  loginAlert() {
    this.alert.cancelOkBtn('', '로그인 후 이용 가능한 서비스예요.<br>로그인 하시겠어요?').then(res => {
      if (res) {
        this.navCtrl.navigateRoot('/tabs/login');
      }
    });
  }

  /** 뒤로 가기 */
  async goBack() {
    const url = this.router.url;
    await this.navCtrl.pop();
    if (url === this.router.url) {
      this.navCtrl.navigateRoot('/tabs/home');
    }
  }

  /** 수리 요청서 고장 증상 내역 */
  symptomList(symptom: Array<Symptom>) {
    const touch = symptom.some(symptom => symptom.type === '터치 불가');
    const screen = symptom.some(symptom => symptom.type === '화면 이상(멍, 줄)');
    return symptom.reduce((prev: string, curr: Symptom) => {
      if (curr.type === '정품 액정' || curr.type === '카피 액정') {
        if (curr.instantSwitch !== undefined) {
          if (touch && screen) {
            return prev
              ? `${prev}, 액정 파손(${curr.type} / 터치 불가, 화면 이상(멍, 줄))`
              : `액정 파손(${curr.type} / 터치 불가, 화면 이상(멍, 줄))`;
          } else if (touch) {
            return prev ? `${prev}, 액정 파손(${curr.type} / 터치 불가)` : `액정 파손(${curr.type} / 터치 불가)`;
          } else if (screen) {
            return prev ? `${prev}, 액정 파손(${curr.type} / 화면 이상(멍, 줄))` : `액정 파손(${curr.type} / 화면 이상(멍, 줄))`;
          } else {
            return prev ? `${prev}, 액정 파손(${curr.type} / 해당 없음)` : `액정 파손(${curr.type} / 해당 없음)`;
          }
        } else {
          return prev ? `${prev}, 액정 파손(${curr.type})` : `액정 파손(${curr.type})`;
        }
      } else if (curr.type === '터치 불가' || curr.type === '화면 이상(멍, 줄)') {
        return prev;
      } else {
        return prev ? `${prev}, ${curr.type}` : curr.type;
      }
    }, '');
  }

  /** 수리 요청서 견적 */
  estimateCost(symptom: Array<Symptom>) {
    return symptom.reduce((prev: number, curr: Symptom) => {
      return prev + curr.cost;
    }, 0);
  }

  /** 작업 이력 */
  async getRepairHistory(fixerId: string) {
    return await this.db
      .collection$('repair', (ref: any) => ref.where('fixerId', '==', fixerId).where('status', '==', '수리 완료'))
      .pipe(take(1))
      .toPromise();
  }
}
