import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { IonList, ModalController } from '@ionic/angular';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { DbService, leftJoinDocument } from 'src/app/services/db.service';
import { LoadingService } from 'src/app/services/loading.service';
import { FixerProfilePage } from '../fixer-profile/fixer-profile.page';
import { ImageDetailComponent } from '../image-detail/image-detail.component';
import { RequestFormPriceComponent } from '../request-form-price/request-form-price.component';
import * as firebase from 'firebase';
import { ImageService } from 'src/app/services/image.service';
import { PushService } from 'src/app/services/push.service';
import { RequestFormPage } from '../request-form/request-form.page';
import { ReviewWritePage } from '../review-write/review-write.page';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.page.html',
  styleUrls: ['./chat.page.scss'],
})
export class ChatPage implements OnInit {
  @ViewChild(IonList) ionList: IonList;
  @ViewChild('imgInput') imgInput: ElementRef;

  isComplete = false;
  isChatComplete = true;

  chatList = [];
  chat: any;
  msgLength: number;

  isOpen = false;
  content = '';

  chatListSub: Subscription;
  chatSub: Subscription;

  isMobile = window.innerWidth < 769;

  chatId: string;

  constructor(
    public auth: AuthService,
    public data: DataService,
    private alertService: AlertService,
    private modalController: ModalController,
    private dialog: MatDialog,
    private db: DbService,
    private loader: LoadingService,
    private afs: AngularFirestore,
    private image: ImageService,
    private push: PushService,
    private common: CommonService
  ) {}

  ngOnInit() {
    window.addEventListener('resize', (ev: any) => {
      this.isMobile = ev.currentTarget.innerWidth < 769;
    });
    window.addEventListener('keyup', ev => {
      if (this.chat && ev.key === 'Enter' && this.content.trim()) {
        this.send();
      }
    });
    this.getChatList(!!this.chatId);
  }

  ngOnDestroy() {
    if (this.chatListSub) {
      this.chatListSub.unsubscribe();
    }
    if (this.chatSub) {
      this.chatSub.unsubscribe();
    }
  }

  // 수락하기
  async requestFormModal() {
    if (this.chat.repairId.updateSwitch) {
      this.db.updateAt(`repair/${this.chat.repairId.id}`, { updateSwitch: false });
    }
    if (typeof this.chat.repairId.fixerId === 'string') {
      this.chat.repairId.fixerId = await this.db.doc$(`users/${this.chat.repairId.fixerId}`).pipe(take(1)).toPromise();
    }
    this.done();
    const modal = await this.modalController.create({
      component: RequestFormPage,
      cssClass: 'detail-modal',
      componentProps: { repair: this.chat.repairId },
    });
    await modal.present();
  }

  // 수리 완료 확인
  fix() {
    this.alertService
      .cancelOkBtn(
        '',
        `고객님의 ${this.chat.repairId.name} 수리가 완료되어<br>최종 기능 테스트 중입니다.<br>수리 완료됨을 확인하셨나요?`,
        '',
        '닫기',
        '네, 확인했어요.'
      )
      .then(async res => {
        if (res) {
          const pointObj = {
            dateCreated: new Date().toISOString(),
            uid: this.chat.partner.uid,
            type: this.chat.repairId.payment === '카드 결제' ? '충전' : '사용',
            point:
              (this.data.estimateCost(this.chat.repairId.symptom) + this.chat.repairId.moveCost) *
              (this.chat.repairId.payment === '카드 결제' ? 0.8 : 0.2),
            completeSwitch: false,
            repairId: this.chat.repairId.id,
          };
          await this.loader.show();
          await Promise.all([
            this.db.updateAt(`repair/${this.chat.repairId.id}`, { status: '수리 완료' }),
            this.db.updateAt(`users/${this.chat.partner.uid}`, {
              repairId: '',
              point: firebase.default.firestore.FieldValue.increment(this.chat.repairId.payment === '카드 결제' ? pointObj.point : -pointObj.point),
            }),
            this.db.updateAt(`point/${this.common.generateFilename()}`, pointObj),
          ]);
          this.loader.hide();
          setTimeout(() => {
            this.alertService.toast('수리 완료를 확인했어요.<br>픽서님의 서비스 리뷰를 작성해 보세요!');
          }, 200);

          const obj = {
            dateCreated: new Date().toISOString(),
            uid: '안내',
            imgSwitch: false,
          };
          this.db.updateAt(`chat/${this.chatId}`, {
            message: firebase.default.firestore.FieldValue.arrayUnion(
              {
                ...obj,
                content: `수리 완료를 확인하셨어요. ${this.chat.partner.name} 픽서의 서비스는 만족스러우셨나요? 리뷰를 작성해 보세요!`,
                type: '고객',
              },
              {
                ...obj,
                content: `${this.auth.user.name} 고객님이 수리 완료를 확인하셨어요. ${this.auth.user.name} 고객님과의 수리 작업건은 어떠셨나요? 리뷰를 작성해 보세요!`,
                type: '픽서',
              }
            ),
          });

          if (this.auth.user.pushSwitch && !this.auth.user.exitSwitch && this.auth.user.chatRoomId !== this.chatId) {
            this.push.sendPush(this.auth.user.fixerSwitch, this.auth.user.pushId, '픽스케어', '메시지가 도착했습니다.', {
              url: `/chat-detail?chatId=${this.chatId}`,
            });
          }
          if (this.chat.partner.pushSwitch && !this.chat.partner.exitSwitch && this.chat.partner.chatRoomId !== this.chatId) {
            this.push.sendPush(this.chat.partner.fixerSwitch, this.chat.partner.pushId, '픽서', '메시지가 도착했습니다.', {
              url: `/chat-detail?chatId=${this.chatId}`,
            });
          }

          this.db.updateAt(`users/${this.chat.partner.uid}`, { newNotification: true });
          this.db.updateAt(`notification/${this.common.generateFilename()}`, {
            dateCreated: new Date().toISOString(),
            uid: this.chat.partner.uid,
            title: '수리 완료 확인',
            content: '고객님이 수리 완료를 확인했어요. 고객 리뷰를 남겨보세요!',
            img: '',
            url: `/request-form?repairId=${this.chat.repairId.id}`,
            readSwitch: false,
          });
          if (this.chat.partner.pushSwitch && !this.chat.partner.exitSwitch) {
            this.push.sendPush(
              this.chat.partner.fixerSwitch,
              this.chat.partner.pushId,
              '수리 완료 확인',
              '고객님이 수리 완료를 확인했어요. 고객 리뷰를 남겨보세요!',
              {
                url: `/request-form?repairId=${this.chat.repairId.id}`,
              }
            );
          }
          this.data.changeRepair.next(true);
        }
      });
  }

  // 리뷰 작성하기
  async reviewWriteModal() {
    this.done();
    const modal = await this.modalController.create({
      component: ReviewWritePage,
      cssClass: 'review-modal',
      backdropDismiss: false,
      componentProps: { user: this.chat.partner, repairId: this.chat.repairId.id },
    });
    await modal.present();
  }

  // 채팅 목록 불러오기
  async getChatList(isChatId: boolean) {
    await this.loader.show();
    this.chatListSub = this.db
      .collection$('chat', (ref: any) => ref.where('uid', 'array-contains', this.auth.user.uid).orderBy('dateCreated', 'desc'))
      .pipe(
        switchMap(chatList => {
          const filterChatList = chatList.filter(chat => !chat[this.auth.user.uid].exitSwitch);
          return filterChatList.length > 0
            ? combineLatest(
                filterChatList.map(chat => {
                  const partnerId = this.auth.user.uid === chat.uid[0] ? chat.uid[1] : chat.uid[0];
                  return this.db.doc$(`users/${partnerId}`).pipe(
                    map(partner => {
                      const message = chat.message.filter((msg: any) => msg.uid !== '안내' || (msg.uid === '안내' && msg.type === '고객'));
                      const exitIndex = chat[this.auth.user.uid].exitIndex;
                      const lastIndex = chat[this.auth.user.uid].lastIndex;
                      const badge = message.length - (exitIndex > lastIndex ? exitIndex : lastIndex);
                      return { ...chat, message, partner, badge };
                    })
                  );
                })
              )
            : of([]);
        })
      )
      .subscribe(chatList => {
        const isMessage = chatList
          .filter(chat => chat.message.length > 0)
          .sort(
            (a, b) =>
              new Date(b.message[b.message.length - 1].dateCreated).getTime() - new Date(a.message[a.message.length - 1].dateCreated).getTime()
          );
        const noMessage = chatList.filter(chat => 0 >= chat.message.length);
        this.chatList = isMessage.concat(noMessage);
        if (!isChatId) {
          this.isComplete = true;
          this.loader.hide();
        }
      });
    if (isChatId) {
      this.goChatDetail(this.chatId, true);
    }
  }

  // 마지막 채팅 일자 표시
  displayLastChatDate(date: string) {
    if (new Date(date).toLocaleDateString('ko-KR') === new Date().toLocaleDateString('ko-KR')) {
      return new Date(date).toTimeString().slice(0, 5);
    } else if (
      new Date(date).toLocaleDateString('ko-KR') === new Date(new Date(date).setDate(new Date().getDate() - 1)).toLocaleDateString('ko-KR')
    ) {
      return '어제';
    } else {
      const splitDate = new Date(date).toLocaleDateString('ko-KR').replace(/(\s*)/g, '').split('.');
      return `${splitDate[1]}월 ${splitDate[2]}일`;
    }
  }

  // 채팅방 상세 (모바일)
  async goChatDetail(chatId: string, isChatId = false) {
    if (chatId !== this.chat?.id) {
      if (this.chatSub) {
        this.chatSub.unsubscribe();
      }
      this.isChatComplete = false;
      this.isOpen = false;
      if (!isChatId) {
        await this.loader.show();
      }
      this.chatSub = this.db
        .doc$(`chat/${chatId}`)
        .pipe(
          leftJoinDocument(this.afs, 'repairId', 'repair'),
          switchMap((chat: any) => {
            const partnerId = this.auth.user.uid === chat.uid[0] ? chat.uid[1] : chat.uid[0];
            return combineLatest([
              of(chat),
              this.db.doc$(`users/${partnerId}`),
              this.db.collection$('review', (ref: any) => ref.where('uid', '==', this.auth.user.uid).where('repairId', '==', chat.repairId.id)),
            ]);
          }),
          map(([chat, partner, review]) => {
            const message = chat.message.filter((msg: any) => msg.uid !== '안내' || (msg.uid === '안내' && msg.type === '고객'));
            return { ...chat, message, partner, review };
          })
        )
        .subscribe(chat => {
          this.chat = chat;
          if (!this.isChatComplete) {
            this.scrollToBottom();
            this.isChatComplete = true;
            this.updateReadIndex();
            this.isComplete = true;
            this.loader.hide();
          } else {
            if (this.chat.message.length > 0 && this.auth.user.uid === this.chat.message[this.chat.message.length - 1].uid) {
              this.scrollToBottom(true);
            }
            if (this.msgLength !== this.chat.message.length) {
              this.updateReadIndex();
            }
          }
        });
    } else {
      this.scrollToBottom(true);
    }
  }

  // 읽은 채팅 수 업데이트
  updateReadIndex() {
    const readIndex = this.chat.message.filter((msg: any) => msg.uid !== this.auth.user.uid).length;
    this.db.updateMap(`chat/${this.chat.id}`, {
      [`${this.auth.user.uid}.readIndex`]: readIndex,
      [`${this.auth.user.uid}.lastIndex`]: this.chat.message.length,
    });
    this.msgLength = this.chat.message.length;
  }

  // 아래로 스크롤
  scrollToBottom(isSmooth = false) {
    const container = document.getElementsByClassName('chat_content_wrap')[0];
    const content = document.getElementsByClassName('chat_list')[0];
    container.scroll({ top: content?.scrollHeight, behavior: isSmooth ? 'smooth' : 'auto' });
  }

  // 뒤로가기 (모바일)
  back() {
    this.chat = null;
    if (this.chatSub) {
      this.chatSub.unsubscribe();
    }
  }

  // 채팅 날짜 표시
  displayChatDate(chatDate: string, pastDate: string) {
    const date = (date: string) => {
      const splitDate = new Date(date).toLocaleDateString('ko-KR').replace(/(\s*)/g, '').split('.');
      const getDay = new Date(splitDate.join('.')).getDay();
      return `${splitDate[0]}년 ${splitDate[1]}월 ${splitDate[2]}일${
        getDay === 0
          ? ' 일요일'
          : getDay === 1
          ? ' 월요일'
          : getDay === 2
          ? ' 화요일'
          : getDay === 3
          ? ' 수요일'
          : getDay === 4
          ? ' 목요일'
          : getDay === 5
          ? ' 금요일'
          : getDay === 6
          ? ' 토요일'
          : ''
      }`;
    };
    return date(chatDate) !== date(pastDate) && date(chatDate);
  }

  // 메시지 전송 시간 표시
  displayChatTime(date: string) {
    return new Date(date).toTimeString().slice(0, 5);
  }

  // 채팅 상태
  msgInput() {
    if (this.chat?.partner.exitSwitch) {
      this.isOpen = false;
      this.content = '';
      return [true, '탈퇴한 회원입니다.'];
    } else if (this.auth.user && this.chat && this.auth.user.blockUser.includes(this.chat.partner.uid)) {
      this.isOpen = false;
      this.content = '';
      return [true, '차단한 회원입니다.'];
    } else {
      return [false, ''];
    }
  }

  // 사진 전송 영역 보기
  imageAreaClick() {
    this.isOpen || this.scrollToBottom();
    this.isOpen = !this.isOpen;
  }

  // 메시지 전송
  async send(imgSwitch = false, url = '') {
    const obj = {
      dateCreated: new Date().toISOString(),
      uid: this.auth.user.uid,
      content: imgSwitch ? url : this.content,
      imgSwitch,
    };
    this.db.updateAt(`chat/${this.chat.id}`, { message: firebase.default.firestore.FieldValue.arrayUnion(obj) });
    this.content = '';

    this.updateReadIndex();

    if (this.chat[this.chat.partner.uid].exitSwitch) {
      await this.db.updateMap(`chat/${this.chat.id}`, { [`${this.chat.partner.uid}.exitSwitch`]: false });
    }
    if (this.chat.partner.pushSwitch && !this.chat.partner.exitSwitch && this.chat.partner.chatRoomId !== this.chat.id) {
      this.push.sendPush(this.chat.partner.fixerSwitch, this.chat.partner.pushId, '픽서', '메시지가 도착했습니다.', {
        url: `/chat-detail?chatId=${this.chat.id}`,
      });
    }
  }

  // 이미지 전송
  sendImg(file: File) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async () => {
      await this.loader.show();
      this.send(true, await this.image.uploadFile(reader.result.toString(), 'chat/'));
      this.loader.hide();
      this.imgInput.nativeElement.value = '';
    };
  }

  // 모달 닫기
  done() {
    this.modalController.dismiss();
  }

  // 이미지 디테일 모달
  async imageDetailModal(src: string) {
    this.dialog.open(ImageDetailComponent, { data: [src] });
  }

  // 나가기
  exitAlert(chatId: string, msgLength: number, chat: any) {
    this.alertService
      .cancelOkBtn('', '채팅방에서 나가시겠어요?<br>나가기를 하면 대화 내용이 모두 삭제되고 채팅 목록에서도 삭제 돼요.')
      .then(async res => {
        if (res) {
          await this.loader.show();
          this.ionList.closeSlidingItems();
          if (this.chat && this.chat.id === chatId) {
            this.back();
          }
          const readIndex = chat.message.filter(
            (msg: any) => msg.uid !== this.auth.user.uid && (msg.uid !== '안내' || (msg.uid === '안내' && msg.type === '고객'))
          ).length;
          await this.db.updateMap(`chat/${chatId}`, {
            [`${this.auth.user.uid}.readIndex`]: readIndex,
            [`${this.auth.user.uid}.lastIndex`]: msgLength,
            [`${this.auth.user.uid}.exitSwitch`]: true,
            [`${this.auth.user.uid}.exitIndex`]: msgLength,
          });
          this.loader.hide();
          this.alertService.toast('채팅방을 나갔어요.');
        }
      });
  }

  // 픽서 프로필 모달
  async fixerProfileModal() {
    this.done();
    const modal = await this.modalController.create({
      component: FixerProfilePage,
      cssClass: 'profile-modal',
      componentProps: { fixerId: this.chat.partner.uid, repairId: this.chat.repairId },
    });
    await modal.present();
  }

  // 예상견적 모달
  requestPriceModal() {
    const dialogRef = this.dialog.open(RequestFormPriceComponent, {
      panelClass: 'price-dialog',
    });
    dialogRef.componentInstance.repair = this.chat?.repairId;
  }

  // trackBy
  trackById(index: number, chat: any) {
    return chat.id;
  }

  // trackBy
  trackByItem(index: number, msg: any) {
    return msg.dateCreated;
  }
}
