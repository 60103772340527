import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalController, NavController } from '@ionic/angular';
import { take } from 'rxjs/operators';
import { Users } from 'src/app/models/users.model';
import { AlertService } from 'src/app/services/alert.service';
import { DbService } from 'src/app/services/db.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PersonalInfoComponent } from '../../terms-dialog/personal-info/personal-info.component';
import { ServiceComponent } from '../../terms-dialog/service/service.component';
import { CommonService } from 'src/app/services/common.service';
import { SocialService } from 'src/app/services/social.service';
import * as firebase from 'firebase';

@Component({
  selector: 'app-atcinfo',
  templateUrl: './atcinfo.page.html',
  styleUrls: ['./atcinfo.page.scss'],
})
export class AtcinfoPage implements OnInit {
  users: Users = {
    uid: '',
    dateCreated: '',
    loginType: [''],
    email: '',
    name: '',
    phoneNum: '',
    exitSwitch: false,
    exitReason: ['', ''],
    deleteSwitch: false,
    pushId: [],
    pushSwitch: true,
    fixerSwitch: false,
    point: 0,
    approvalSwitch: false,
    suspendSwitch: false,
    profileImg: '',
    model: [],
    businessLicense: '',
    storeAddress: '',
    storeDetailAddress: '',
    storeCoordinates: {
      lat: null,
      lng: null,
    },
    storeImg: [],
    introduction: '',
    bankName: '',
    accountNum: '',
    chatRoomId: '',
    blockUser: [],
    reportReview: [],
    workSwitch: false,
    requestDate: '',
    reportPost: [],
    newNotification: false,
    repairId: '',
  };

  termsAll = false;
  checkbox: any = {
    service: false,
    privacy: false,
  };

  checkPhone = false;
  usedPhone = false;

  email = '';
  type = '';
  credential: any;

  constructor(
    private navc: NavController,
    private modalController: ModalController,
    private dialog: MatDialog,
    private db: DbService,
    private alert: AlertService,
    private loader: LoadingService,
    private common: CommonService,
    private social: SocialService
  ) {}

  ngOnInit() {}

  // 모달 닫기
  done() {
    this.modalController.dismiss();
  }

  // 유효성 검사
  validate(type: string) {
    let check: boolean;
    switch (type) {
      case 'name':
        const nameRegExp = /^([a-zA-Z0-9ㄱ-ㅎ|ㅏ-ㅣ|가-힣]).{1,5}$/;
        check = nameRegExp.test(this.users.name);
        break;
      case 'phone':
        const phoneRegExp = /^\d{3}\d{3,4}\d{4}$/;
        check = phoneRegExp.test(this.users.phoneNum);
        break;
    }
    return check;
  }

  // 중복검사 - 핸드폰
  async overlapPhone() {
    const result = await this.db
      .collection$('users', (ref: any) => ref.where('phoneNum', '==', this.users.phoneNum).where('exitSwitch', '==', false))
      .pipe(take(1))
      .toPromise();
    this.checkPhone = true;
    this.usedPhone = result?.length > 0;
  }

  // 이용약관
  termsChecked(all: boolean) {
    const { service, privacy } = this.checkbox;
    if (all) {
      if (this.termsAll) {
        this.checkbox.privacy = true;
        this.checkbox.service = true;
      } else if (privacy && service) {
        this.checkbox.privacy = false;
        this.checkbox.service = false;
      }
    }
    if (!all) {
      this.termsAll = false;
      if (privacy && service) {
        this.termsAll = true;
      }
    }
  }

  // 소셜 회원가입
  async join() {
    if (this.chkNotEntered()) {
      await this.loader.show();
      // 중복 확인
      await this.overlapPhone();

      if (this.type === 'naver' || this.type === 'kakao') {
        this.users.uid = this.common.generateFilename(28);
        const firebaseToken = await this.social.linkSocialLogin(this.users.uid, this.type === 'naver' ? 'NAVER' : 'KAKAO', true, this.email);
        await firebase.default.auth().signInWithCustomToken(firebaseToken);
      } else {
        this.users.uid = await firebase.default
          .auth()
          .signInWithCredential(this.credential)
          .then(({ user: { uid } }) => uid);
      }
      localStorage.setItem('uid', this.users.uid);

      await this.db.updateAt(`users/${this.users.uid}`, {
        ...this.users,
        email: this.email,
        dateCreated: new Date().toISOString(),
        loginType: [this.type],
      });

      this.navc.navigateRoot('/join-complete', { animated: false, queryParams: { name: this.users.name } });
      this.done();
      this.loader.hide();
    }
  }

  // 이용약관
  async goService() {
    this.dialog.open(ServiceComponent, {
      panelClass: 'terms-dialog',
      disableClose: false,
    });
  }

  // 개인정보
  async goPersonal() {
    this.dialog.open(PersonalInfoComponent, {
      panelClass: 'terms-dialog',
      disableClose: false,
    });
  }

  // 입력이 안된 정보가 있는지 확인
  chkNotEntered() {
    // 이름
    if (!this.validate('name')) {
      this.alert.okBtn('', '이름을 확인해 주세요.');
      return false;
    }
    // 전화번호
    if (!this.checkPhone) {
      this.alert.okBtn('', '전화번호를 확인해 주세요.');
      return false;
    }
    // 약관 동의
    if (!this.termsAll) {
      this.alert.okBtn('', '약관에 동의해 주세요.');
      return false;
    }
    return true;
  }
}
