import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalController, NavController } from '@ionic/angular';
import { Repair } from 'src/app/models/repair.model';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { DbService } from 'src/app/services/db.service';
import { LoadingService } from 'src/app/services/loading.service';
import { FixerProfilePage } from '../fixer-profile/fixer-profile.page';
import { ImageDetailComponent } from '../image-detail/image-detail.component';
import { LocationPage } from '../location/location.page';
import { ReportFormPage } from '../report-form/report-form.page';
import { RequestFormInfoComponent } from '../request-form-info/request-form-info.component';
import { RequestFormRejectPage } from '../request-form-reject/request-form-reject.page';
import { ReviewWritePage } from '../review-write/review-write.page';
import { take } from 'rxjs/operators';
import { Users } from 'src/app/models/users.model';
import { ChatService } from 'src/app/services/chat.service';
import * as firebase from 'firebase';
import { PushService } from 'src/app/services/push.service';
import { CommonService } from 'src/app/services/common.service';
import { Subscription } from 'rxjs';
import { ChatPage } from '../chat/chat.page';

@Component({
  selector: 'app-request-form',
  templateUrl: './request-form.page.html',
  styleUrls: ['./request-form.page.scss'],
})
export class RequestFormPage implements OnInit {
  repair: Repair;
  repairHistory = 0;
  newChat = false;

  sub = new Subscription();

  constructor(
    public data: DataService,
    private modalController: ModalController,
    private alertService: AlertService,
    private dialog: MatDialog,
    private db: DbService,
    private loader: LoadingService,
    private auth: AuthService,
    private navCtrl: NavController,
    private chat: ChatService,
    private push: PushService,
    private common: CommonService
  ) {}

  ngOnInit() {
    this.getRepairHistory();
  }

  ionViewWillEnter() {
    const chatSub = this.db
      .collection$('chat', (ref: any) => ref.where('repairId', '==', this.repair.id).where('uid', 'array-contains', this.auth.user.uid))
      .subscribe(data => {
        if (data.length > 0) {
          const message = data[0].message.filter(
            (msg: any) => msg.uid !== this.auth.user.uid && (msg.uid !== '안내' || (msg.uid === '안내' && msg.type === '고객'))
          );
          const badge = message.length - data[0][this.auth.user.uid].readIndex;
          this.newChat = badge > 0;
        } else {
          this.newChat = false;
        }
      });
    this.sub.add(chatSub);
  }

  ionViewWillLeave() {
    this.sub.unsubscribe();
  }

  // 작업 이력 불러오기
  async getRepairHistory() {
    if (this.repair.fixerId) {
      this.repairHistory = (await this.data.getRepairHistory(this.repair.fixerId.uid)).length;
    }
  }

  // 모달 닫기
  done() {
    this.modalController.dismiss();
  }

  // 요청서 상태 안내 모달
  requestInfoModal() {
    this.dialog.open(RequestFormInfoComponent, {
      panelClass: 'info-dialog',
    });
  }

  // 고장 증상
  returnSymptom() {
    if (this.repair.status === '수락 대기 중' && this.repair.fixerSymptom.length > 0) {
      return this.repair.fixerSymptom;
    } else {
      return this.repair.symptom;
    }
  }

  // 고장 증상
  symptom() {
    const touch = this.returnSymptom().some((symptom: any) => symptom.type === '터치 불가');
    const screen = this.returnSymptom().some((symptom: any) => symptom.type === '화면 이상(멍, 줄)');
    return this.returnSymptom()
      .map((symptom: any) => {
        let type = symptom.type;
        let isScreen = false;
        if (symptom.type === '정품 액정' || symptom.type === '카피 액정') {
          type = `액정 파손(${symptom.type}`;
          isScreen = true;
          if (symptom.instantSwitch !== undefined) {
            if (touch && screen) {
              type += ' / 터치 불가, 화면 이상(멍, 줄))';
            } else if (touch) {
              type += ' / 터치 불가)';
            } else if (screen) {
              type += ' / 화면 이상(멍, 줄))';
            } else {
              type += ' / 해당 없음)';
            }
          } else {
            type += ')';
          }
        }
        if (symptom.type === '터치 불가' || symptom.type === '화면 이상(멍, 줄)') {
          return null;
        } else {
          return { type, isScreen, cost: symptom.cost };
        }
      })
      .filter(Boolean);
  }

  // 액정 파손 추가 금액
  addCost(): Number {
    const touchCost = this.returnSymptom().filter((symptom: any) => symptom.type === '터치 불가')[0]?.cost || 0;
    const screenCost = this.returnSymptom().filter((symptom: any) => symptom.type === '화면 이상(멍, 줄)')[0]?.cost || 0;
    return touchCost + screenCost;
  }

  // 출동 거리 계산
  getDistance() {
    const meter = this.repair.distance;
    const km = Number(Math.floor(meter / 100) / 10);
    return km > 1 ? `${km}km` : `${meter}m`;
  }

  // 요청 취소
  formCancel() {
    this.alertService
      .cancelOkBtn('', '요청을 취소하시면 해당 요청서가 삭제되고 되돌릴 수 없어요.<br>그래도 요청을 취소하시겠어요?', '', '닫기')
      .then(async res => {
        if (res) {
          if (this.repair.status === '수락 대기 중') {
            this.db.updateAt(`users/${(this.repair.fixerId as Users).uid}`, { newNotification: true, repairId: '' });
            this.db.updateAt(`notification/${this.common.generateFilename()}`, {
              dateCreated: new Date().toISOString(),
              uid: (this.repair.fixerId as Users).uid,
              title: '요청 취소',
              content: '고객님이 요청을 취소했어요. 다른 수리 요청서들을 확인해 보세요.',
              img: '',
              url: `/request-form?repairId=${this.repair.id}`,
              readSwitch: false,
            });
            if ((this.repair.fixerId as Users).pushSwitch && !(this.repair.fixerId as Users).exitSwitch) {
              this.push.sendPush(
                (this.repair.fixerId as Users).fixerSwitch,
                (this.repair.fixerId as Users).pushId,
                '요청 취소',
                '고객님이 요청을 취소했어요. 다른 수리 요청서들을 확인해 보세요.',
                {
                  url: `/request-form?repairId=${this.repair.id}`,
                }
              );
            }
          }

          await this.loader.show();
          await this.db.updateAt(`repair/${this.repair.id}`, { status: '요청 취소' });
          this.loader.hide();
          this.alertService.toast('요청을 취소했어요.');
          this.data.changeRepair.next(true);
          this.done();
        }
      });
  }

  // 불편신고
  async report() {
    await this.loader.show();
    await this.db
      .collection$('report', (ref: any) =>
        ref.where('reportId', '==', this.repair.id).where('uid', '==', this.auth.user.uid).where('type', '==', 'repair')
      )
      .pipe(take(1))
      .toPromise()
      .then(async data => {
        if (data.length > 0) {
          this.alertService.okBtn('', '이미 불편신고를 접수하셨어요.<br>서비스 운영 정책에 따라 조치될 예정입니다.');
        } else {
          this.done();
          const modal = await this.modalController.create({
            component: ReportFormPage,
            cssClass: 'report-form-modal',
            backdropDismiss: false,
            componentProps: { user: this.repair.fixerId, repairId: this.repair.id },
          });
          await modal.present();
        }
      });
    this.loader.hide();
  }

  // 이미지 디테일 모달
  async imageDetailModal(src: string) {
    this.dialog.open(ImageDetailComponent, { data: [src] });
  }

  // 리뷰 작성 모달
  async reviewWriteModal() {
    this.done();
    const modal = await this.modalController.create({
      component: ReviewWritePage,
      cssClass: 'review-modal',
      backdropDismiss: false,
      componentProps: { user: this.repair.fixerId, repairId: this.repair.id },
    });
    await modal.present();
  }

  // 수락 거절 모달
  async formRejectModal() {
    this.done();
    const modal = await this.modalController.create({
      component: RequestFormRejectPage,
      cssClass: 'report-reject-modal',
      backdropDismiss: false,
      componentProps: { repair: this.repair },
    });
    await modal.present();
  }

  // 수락하기
  resolve() {
    if (this.repair.repair === '출동 수리') {
      this.alertService
        .cancelOkBtn(
          'header',
          `응답에 수락하시면<br>고객님의 위치로 픽서님이 출동하며,<br>출동 중 상태로 변경됩니다.<br>${
            (this.repair.fixerId as Users).name
          } 픽서의 응답을 수락하시겠어요?`,
          '응답 수락'
        )
        .then(async res => {
          if (res) {
            await this.loader.show();
            await this.db.updateAt(`repair/${this.repair.id}`, { status: '출동 중', symptom: this.returnSymptom(), fixerSymptom: [] });
            this.loader.hide();
            this.alertService.toast('응답을 수락했어요.');
            this.data.changeRepair.next(true);
            this.done();

            this.chat.createChatRoom((this.repair.fixerId as Users).uid, this.repair.id).then(chatId => {
              const obj = {
                dateCreated: new Date().toISOString(),
                uid: '안내',
                content: `${
                  (this.repair.fixerId as Users).name
                } 픽서의 응답을 수락 했어요. 픽서가 고객님의 위치로 이동 중이에요. 잠시만 기다려 주세요.`,
                imgSwitch: false,
              };
              this.db.updateAt(`chat/${chatId}`, { message: firebase.default.firestore.FieldValue.arrayUnion(obj) });

              if (this.auth.user.pushSwitch && !this.auth.user.exitSwitch && this.auth.user.chatRoomId !== chatId) {
                this.push.sendPush(this.auth.user.fixerSwitch, this.auth.user.pushId, '픽스케어', '메시지가 도착했습니다.', {
                  url: `/chat-detail?chatId=${chatId}`,
                });
              }
              if (
                (this.repair.fixerId as Users).pushSwitch &&
                !(this.repair.fixerId as Users).exitSwitch &&
                (this.repair.fixerId as Users).chatRoomId !== chatId
              ) {
                this.push.sendPush(
                  (this.repair.fixerId as Users).fixerSwitch,
                  (this.repair.fixerId as Users).pushId,
                  '픽서',
                  '메시지가 도착했습니다.',
                  {
                    url: `/chat-detail?chatId=${chatId}`,
                  }
                );
              }
            });

            this.db.updateAt(`users/${this.auth.user.uid}`, { newNotification: true });
            this.db.updateAt(`notification/${this.common.generateFilename()}`, {
              dateCreated: new Date().toISOString(),
              uid: this.auth.user.uid,
              title: '출동 시작',
              content: '픽서님이 출동을 시작했어요! 도착까지 잠시만 기다려주세요.',
              img: '',
              url: `/request-form?repairId=${this.repair.id}`,
              readSwitch: false,
            });
            if (this.auth.user.pushSwitch && !this.auth.user.exitSwitch) {
              this.push.sendPush(
                this.auth.user.fixerSwitch,
                this.auth.user.pushId,
                '출동 시작',
                '픽서님이 출동을 시작했어요! 도착까지 잠시만 기다려주세요.',
                {
                  url: `/request-form?repairId=${this.repair.id}`,
                }
              );
            }

            this.db.updateAt(`users/${(this.repair.fixerId as Users).uid}`, { newNotification: true });
            this.db.updateAt(`notification/${this.common.generateFilename()}`, {
              dateCreated: new Date().toISOString(),
              uid: (this.repair.fixerId as Users).uid,
              title: '응답 수락',
              content: `${this.auth.user.name} 고객님이 응답을 수락했어요!`,
              img: '',
              url: `/request-form?repairId=${this.repair.id}`,
              readSwitch: false,
            });
            if ((this.repair.fixerId as Users).pushSwitch && !(this.repair.fixerId as Users).exitSwitch) {
              this.push.sendPush(
                (this.repair.fixerId as Users).fixerSwitch,
                (this.repair.fixerId as Users).pushId,
                '응답 수락',
                `${this.auth.user.name} 고객님이 응답을 수락했어요!`,
                {
                  url: `/request-form?repairId=${this.repair.id}`,
                }
              );
            }
          }
        });
    } else {
      this.alertService
        .cancelOkBtn(
          'header',
          `응답에 수락하시면<br>픽서님이 고객님의 기기 수리 작업을 준비하며 수리 작업 중 상태로 변경됩니다.<br>${
            (this.repair.fixerId as Users).name
          } 픽서의 응답을 수락하시겠어요?`,
          '응답 수락'
        )
        .then(async res => {
          if (res) {
            this.alertService
              .okBtn('', `${(this.repair.fixerId as Users).name} 픽서님의 응답을 수락했어요. 픽서님의 매장 위치를 확인하시고 방문해 주세요.`)
              .then(async () => {
                await this.loader.show();
                await this.db.updateAt(`repair/${this.repair.id}`, { status: '수리 작업 중', symptom: this.returnSymptom(), fixerSymptom: [] });
                this.data.changeRepair.next(true);
                this.done();
                this.loader.hide();
                this.locationModal();

                this.chat.createChatRoom((this.repair.fixerId as Users).uid, this.repair.id).then(chatId => {
                  const obj = {
                    dateCreated: new Date().toISOString(),
                    uid: '안내',
                    content: `${(this.repair.fixerId as Users).name} 픽서가 고객님의 ${
                      this.repair.type
                    }을 수리 중이에요. 수리 완료까지 잠시만 기다려 주세요.`,
                    imgSwitch: false,
                  };
                  this.db.updateAt(`chat/${chatId}`, { message: firebase.default.firestore.FieldValue.arrayUnion(obj) });

                  if (this.auth.user.pushSwitch && !this.auth.user.exitSwitch && this.auth.user.chatRoomId !== chatId) {
                    this.push.sendPush(this.auth.user.fixerSwitch, this.auth.user.pushId, '픽스케어', '메시지가 도착했습니다.', {
                      url: `/chat-detail?chatId=${chatId}`,
                    });
                  }
                  if (
                    (this.repair.fixerId as Users).pushSwitch &&
                    !(this.repair.fixerId as Users).exitSwitch &&
                    (this.repair.fixerId as Users).chatRoomId !== chatId
                  ) {
                    this.push.sendPush(
                      (this.repair.fixerId as Users).fixerSwitch,
                      (this.repair.fixerId as Users).pushId,
                      '픽서',
                      '메시지가 도착했습니다.',
                      {
                        url: `/chat-detail?chatId=${chatId}`,
                      }
                    );
                  }
                });

                this.db.updateAt(`users/${this.auth.user.uid}`, { newNotification: true });
                this.db.updateAt(`notification/${this.common.generateFilename()}`, {
                  dateCreated: new Date().toISOString(),
                  uid: this.auth.user.uid,
                  title: '수리 작업 시작',
                  content: '픽서님이 수리 작업을 시작했어요! 완료까지 잠시만 기다려주세요.',
                  img: '',
                  url: `/request-form?repairId=${this.repair.id}`,
                  readSwitch: false,
                });
                if (this.auth.user.pushSwitch && !this.auth.user.exitSwitch) {
                  this.push.sendPush(
                    this.auth.user.fixerSwitch,
                    this.auth.user.pushId,
                    '수리 작업 시작',
                    '픽서님이 수리 작업을 시작했어요! 완료까지 잠시만 기다려주세요.',
                    {
                      url: `/request-form?repairId=${this.repair.id}`,
                    }
                  );
                }
              });
          }
        });
    }
  }

  // 위치모달
  async locationModal() {
    const modal = await this.modalController.create({
      component: LocationPage,
      cssClass: 'location-modal',
      backdropDismiss: false,
      componentProps: { fixer: this.repair.fixerId },
    });
    await modal.present();
  }

  // 수리 완료 확인
  fix() {
    this.alertService
      .cancelOkBtn(
        '',
        `고객님의 ${this.repair.name} 수리가 완료되어<br>최종 기능 테스트 중입니다.<br>수리 완료됨을 확인하셨나요?`,
        '',
        '닫기',
        '네, 확인했어요.'
      )
      .then(async res => {
        if (res) {
          const pointObj = {
            dateCreated: new Date().toISOString(),
            uid: (this.repair.fixerId as Users).uid,
            type: '사용',
            point: (this.data.estimateCost(this.repair.symptom) + this.repair.moveCost) * 0.2,
            completeSwitch: false,
            repairId: this.repair.id,
          };
          await this.loader.show();
          await Promise.all([
            this.db.updateAt(`repair/${this.repair.id}`, { status: '수리 완료' }),
            this.db.updateAt(`users/${(this.repair.fixerId as Users).uid}`, {
              repairId: '',
              point: firebase.default.firestore.FieldValue.increment(-pointObj.point),
            }),
            this.db.updateAt(`point/${this.common.generateFilename()}`, pointObj),
          ]);
          this.loader.hide();
          this.alertService.toast('수리 완료를 확인했어요.<br>픽서님의 서비스 리뷰를 작성해 보세요!');
          this.data.changeRepair.next(true);
          this.done();

          this.chat.createChatRoom((this.repair.fixerId as Users).uid, this.repair.id).then(chatId => {
            const obj = {
              dateCreated: new Date().toISOString(),
              uid: '안내',
              content: `수리 완료를 확인하셨어요. ${(this.repair.fixerId as Users).name} 픽서의 서비스는 만족스러우셨나요? 리뷰를 작성해 보세요!`,
              imgSwitch: false,
            };
            this.db.updateAt(`chat/${chatId}`, { message: firebase.default.firestore.FieldValue.arrayUnion(obj) });

            if (this.auth.user.pushSwitch && !this.auth.user.exitSwitch && this.auth.user.chatRoomId !== chatId) {
              this.push.sendPush(this.auth.user.fixerSwitch, this.auth.user.pushId, '픽스케어', '메시지가 도착했습니다.', {
                url: `/chat-detail?chatId=${chatId}`,
              });
            }
            if (
              (this.repair.fixerId as Users).pushSwitch &&
              !(this.repair.fixerId as Users).exitSwitch &&
              (this.repair.fixerId as Users).chatRoomId !== chatId
            ) {
              this.push.sendPush(
                (this.repair.fixerId as Users).fixerSwitch,
                (this.repair.fixerId as Users).pushId,
                '픽서',
                '메시지가 도착했습니다.',
                {
                  url: `/chat-detail?chatId=${chatId}`,
                }
              );
            }
          });

          this.db.updateAt(`users/${(this.repair.fixerId as Users).uid}`, { newNotification: true });
          this.db.updateAt(`notification/${this.common.generateFilename()}`, {
            dateCreated: new Date().toISOString(),
            uid: (this.repair.fixerId as Users).uid,
            title: '수리 완료 확인',
            content: '고객님이 수리 완료를 확인했어요. 고객 리뷰를 남겨보세요!',
            img: '',
            url: `/request-form?repairId=${this.repair.id}`,
            readSwitch: false,
          });
          if ((this.repair.fixerId as Users).pushSwitch && !(this.repair.fixerId as Users).exitSwitch) {
            this.push.sendPush(
              (this.repair.fixerId as Users).fixerSwitch,
              (this.repair.fixerId as Users).pushId,
              '수리 완료 확인',
              '고객님이 수리 완료를 확인했어요. 고객 리뷰를 남겨보세요!',
              {
                url: `/request-form?repairId=${this.repair.id}`,
              }
            );
          }
        }
      });
  }

  // 픽서 프로필 모달
  async goFixerProfileModal() {
    this.done();
    const modal = await this.modalController.create({
      component: FixerProfilePage,
      cssClass: 'profile-modal',
      componentProps: { fixerId: (this.repair.fixerId as Users).uid, repairId: this.repair.id },
    });
    await modal.present();
  }

  // 채팅 모달
  async chatModal() {
    await this.loader.show();
    const chatId = await this.chat.createChatRoom(this.repair.fixerId.uid, this.repair.id);
    this.loader.hide();
    this.done();
    const modal = await this.modalController.create({
      component: ChatPage,
      cssClass: 'chat-modal',
      componentProps: { chatId },
    });
    await modal.present();
  }
}
