import { Component } from '@angular/core';

import { ModalController, NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { AuthService } from './services/auth.service';
import { LoadingService } from './services/loading.service';
import { OneSignal } from 'onesignal-ngx';
import { PushDetailPage } from './pages/push-detail/push-detail.page';
import { DbService } from './services/db.service';
import { take } from 'rxjs/operators';
import { ChatPage } from './pages/chat/chat.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private mobileAccessibility: MobileAccessibility,
    private screenOrientation: ScreenOrientation,
    private auth: AuthService,
    private loader: LoadingService,
    private oneSignal: OneSignal,
    private navCtrl: NavController,
    private modalController: ModalController,
    private db: DbService
  ) {
    if (!localStorage.getItem('uid')) {
      this.auth.logoutUser();
    }
    if (this.auth.user === undefined) {
      Promise.all([this.loader.show(), this.auth.getUser()]).then(() => {
        this.loader.hide();
        document.getElementsByTagName('ion-router-outlet')[0].classList.remove('hide');
      });
    }
    this.initializeApp();
    this.oneSignalInit();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT_PRIMARY);
      }

      //유저 시스템 폰트사이즈 막기
      if (this.mobileAccessibility) {
        this.mobileAccessibility.usePreferredTextZoom(false);
      }

      if (this.platform.is('android')) {
        this.statusBar.overlaysWebView(false);
        this.statusBar.styleLightContent();
        this.statusBar.backgroundColorByHexString('#000');
      } else {
        this.statusBar.overlaysWebView(false);
        this.statusBar.styleLightContent();
        this.statusBar.backgroundColorByHexString('#000');
      }

      this.splashScreen.hide();
    });
  }

  // 원시그널 세팅
  oneSignalInit() {
    this.oneSignal.init({
      appId: 'e3fe8729-caa4-44c1-8b7f-0ea08c272605',

      //appId: '8fd199d5-479b-4efc-8be3-e0c095ce7acd',
      //safari_web_id: 'web.onesignal.auto.3d9f0610-6ae1-419f-862e-705396ff3ef1',
      safari_web_id: 'web.onesignal.auto.694eada3-a476-4a33-8c61-99aa6c1d60b0',
      notifyButton: {
        enable: true,
        text: {
          'tip.state.unsubscribed': '웹에서 푸시 알림을 받으시려면 클릭해 주세요.',
          'tip.state.subscribed': '웹에서 푸시 알림을 받지 않으시려면 클릭해 주세요.',
          'tip.state.blocked': '웹에서 푸시 알림을 받으시려면 클릭해 주세요.',
          'message.prenotify': '웹에서 메시지를 미리 받으시려면 클릭해 주세요.',
          'message.action.subscribed': '이제 웹에서 푸시 알림을 받을 수 있습니다.',
          'message.action.resubscribed': '이제 웹에서 푸시 알림을 받을 수 있습니다.',
          'message.action.unsubscribed': '이제 웹에서 푸시 알림이 오지 않습니다.',
          'dialog.main.title': '푸시 알림 설정',
          'dialog.main.button.subscribe': '푸시 알림 받기',
          'dialog.main.button.unsubscribe': '푸시 알림 받지 않기',
          'dialog.blocked.title': '푸시 알림 설정',
          'dialog.blocked.message': '다음 사진 처럼 알림 권한을 허용해 주세요.',
        },
      },
      welcomeNotification: { disable: true },
      serviceWorkerParam: { scope: '/assets/js/' },
      serviceWorkerPath: 'assets/js/OneSignalSDKWorker.js',
      notificationClickHandlerMatch: 'origin',
      notificationClickHandlerAction: 'focus',

      allowLocalhostAsSecureOrigin: true,
    });

    this.oneSignal.on('notificationDisplay', async e => {
      console.log('OneSignal notification displayed:', e);

      this.oneSignal.addListenerForNotificationOpened(async click => {
        const url = click.data.addUrlParams ? click.data.addUrlParams : click.data.url;

        if (url) {
          if (url.startsWith('/notice-detail')) {
            this.navCtrl.navigateRoot('/tabs/mypage');
          } else if (url.startsWith('/request-form')) {
            this.navCtrl.navigateRoot('/tabs/request-list');
          } else if (url.startsWith('/chat-detail')) {
            const urlId = url.split('=');
            const chatId = urlId[1];
            this.loader.hide();
            const modal = await this.modalController.create({
              component: ChatPage,
              cssClass: 'chat-modal',
              componentProps: { chatId },
            });
            await modal.present();
            this.loader.hide();
          } else {
            const urlId = url.split('=');

            const notification = await this.db.doc$(`notification/${urlId[1]}`).pipe(take(1)).toPromise();

            const modal = await this.modalController.create({
              component: PushDetailPage,
              componentProps: { notification },
            });
            await modal.present();
          }
        }
      });
    });
  }
}
