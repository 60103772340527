import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private loader: HTMLIonLoadingElement;

  constructor(private loadingCtrl: LoadingController) {}

  async show() {
    // if (!this.loader) {
    this.loader = await this.loadingCtrl.create({
      duration: 5000,
      spinner: 'crescent',
    });
    await this.loader.present();
    // }
  }

  hide() {
    this.loader.dismiss();
    // if (this.loader) {
    //   this.loader.dismiss().then(res => {
    //     if (res) {
    //       this.loader = undefined;
    //     }
    //   });
    // }
  }
}
