import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-image-detail',
  templateUrl: './image-detail.component.html',
  styleUrls: ['./image-detail.component.scss'],
})
export class ImageDetailComponent implements OnInit {
  images;
  slideOpts = {
    zoom: true,
    initialSlide: 0,
    pagination: {
      el: '.swiper-pagination',
      type: 'fraction',
    },
  };

  constructor(private dialog: MatDialogRef<ImageDetailComponent>, @Inject(MAT_DIALOG_DATA) data: { src: string }) {
    this.images = data;
  }

  ngOnInit() {}

  // 다이얼로그 닫기
  done() {
    this.dialog.close();
  }
}
