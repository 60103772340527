import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MbscModule } from '@mobiscroll/angular';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { AnimationController, IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';

// native plugin module
import { Camera } from '@ionic-native/camera/ngx';
import { ImagePicker } from '@ionic-native/image-picker/ngx';

import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { Media } from '@ionic-native/media/ngx';
import { Base64 } from '@ionic-native/base64/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { VideoEditor } from '@ionic-native/video-editor/ngx';
import { File } from '@ionic-native/file/ngx';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import * as firebase from 'firebase/app';
import { environment } from 'src/environments/environment';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { RequestFormPriceComponent } from './pages/request-form-price/request-form-price.component';
import { RequestFormInfoComponent } from './pages/request-form-info/request-form-info.component';
import { ServiceComponent } from './pages/terms-dialog/service/service.component';
import { PersonalInfoComponent } from './pages/terms-dialog/personal-info/personal-info.component';
import { ImageDetailComponent } from './pages/image-detail/image-detail.component';
import { LoginPage } from './pages/account/login/login.page';

import { NgGoogleOneTapModule } from 'ng-google-one-tap';

import { NgbPaginationModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

firebase.default.initializeApp(environment.firebase);

@NgModule({
  declarations: [
    AppComponent,
    RequestFormPriceComponent,
    RequestFormInfoComponent,
    ServiceComponent,
    PersonalInfoComponent,
    ImageDetailComponent,
    LoginPage,
  ],
  entryComponents: [],
  imports: [
    FormsModule,
    MbscModule,
    BrowserModule,
    IonicModule.forRoot({ mode: 'ios', navAnimation: () => new AnimationController().create() }),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    MatDialogModule,
    NgbPaginationModule,
    NgbModule,
    NgGoogleOneTapModule.config({
      client_id: '718687896105-40t08vqollitf7m6c47h5r9vor1prja5.apps.googleusercontent.com',
      disable_exponential_cooldowntime: true,
      authvalidate_by_googleapis: true,
    }),
  ],
  providers: [
    MobileAccessibility,
    StatusBar,
    SplashScreen,
    ScreenOrientation,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Camera,
    ImagePicker,
    MediaCapture,
    Media,
    Base64,
    AndroidPermissions,
    VideoEditor,
    File,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
