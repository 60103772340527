import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { ServiceComponent } from '../service/service.component';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss'],
})
export class PersonalInfoComponent implements OnInit {
  constructor(public auth: AuthService, private dialog: MatDialogRef<ServiceComponent>) {}

  ngOnInit() {}

  done() {
    this.dialog.close();
  }
}
