import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OneSignal } from 'onesignal-ngx';

@Injectable({
  providedIn: 'root',
})
export class PushService {
  constructor(private http: HttpClient, private oneSignal: OneSignal) {}

  async getId() {
    return new Promise<{ userId: string }>(resolve => {
      this.oneSignal
        .getUserId()
        .then(userId => {
          resolve({ userId });
        })
        .catch(() => {
          resolve(null);
        });
    });
  }

  sendPush(isFixer: boolean, pushId: Array<string>, title: string, message: string, data?: any) {
    return new Promise<any>((resolve, reject) => {
      const options = {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
      };
      const body = {
        app_id: isFixer ? 'a1490648-7bcd-4c9f-8cb9-749d697cd29a' : 'e3fe8729-caa4-44c1-8b7f-0ea08c272605',
        headings: { en: title },
        contents: { en: message },
        data,
        include_player_ids: pushId,
        android_channel_id: isFixer ? '22b6e23b-4218-4e35-861a-82e710f3b918' : 'ed345de2-92e6-440a-ab20-66030f04db34',
      };
      if (pushId.length > 0) {
        this.http.post('https://onesignal.com/api/v1/notifications', body, options).subscribe(
          data => {
            resolve(data);
          },
          err => {
            reject(err);
          }
        );
      }
    });
  }
}
