import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Users } from 'src/app/models/users.model';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { DbService } from 'src/app/services/db.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-report-form',
  templateUrl: './report-form.page.html',
  styleUrls: ['./report-form.page.scss'],
})
export class ReportFormPage implements OnInit {
  user: Users;
  repairId: string;

  category: string;
  content = '';

  constructor(
    private modalController: ModalController,
    private alertService: AlertService,
    private db: DbService,
    private auth: AuthService,
    private loader: LoadingService,
    private common: CommonService
  ) {}

  ngOnInit() {}

  // 모달 닫기
  done() {
    this.modalController.dismiss();
  }

  // 신고하기 버튼 활성화
  require() {
    this.category !== '기타(직접 작성)' && (this.content = '');
    return this.category && (this.category === '기타(직접 작성)' ? this.content : true);
  }

  // 신고하기
  report() {
    this.alertService
      .cancelOkBtn('', '신고 사유가 확인되면 서비스 운영 정책에 따라 조치됩니다.<br>해당 신고 사유로 불편신고를 접수하시겠어요?')
      .then(async res => {
        if (res) {
          await this.loader.show();
          await this.db.updateAt(`report/${this.common.generateFilename()}`, {
            dateCreated: new Date().toISOString(),
            reportId: this.repairId,
            uid: this.auth.user.uid,
            category: this.category,
            content: this.content,
            type: 'repair',
          });
          this.loader.hide();
          this.done();
          this.alertService.toast('불편신고를 접수했어요.');
        }
      });
  }
}
