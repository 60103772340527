import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
  },
  {
    path: 'request-list',
    loadChildren: () => import('./pages/request-list/request-list.module').then(m => m.RequestListPageModule),
  },
  {
    path: 'community-list',
    loadChildren: () => import('./pages/community-list/community-list.module').then(m => m.CommunityListPageModule),
  },
  {
    path: 'mypage',
    loadChildren: () => import('./pages/mypage/mypage.module').then(m => m.MypagePageModule),
  },
  {
    path: 'push',
    loadChildren: () => import('./pages/push/push.module').then(m => m.PushPageModule),
  },
  {
    path: 'push-detail',
    loadChildren: () => import('./pages/push-detail/push-detail.module').then(m => m.PushDetailPageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/account/login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: 'join',
    loadChildren: () => import('./pages/account/join/join.module').then(m => m.JoinPageModule),
  },
  {
    path: 'request-write',
    loadChildren: () => import('./pages/request-write/request-write.module').then(m => m.RequestWritePageModule),
  },
  {
    path: 'request-form',
    loadChildren: () => import('./pages/request-form/request-form.module').then(m => m.RequestFormPageModule),
  },
  {
    path: 'service',
    loadChildren: () => import('./pages/terms/service/service.module').then(m => m.ServicePageModule),
  },
  {
    path: 'join-complete',
    loadChildren: () => import('./pages/account/join-complete/join-complete.module').then(m => m.JoinCompletePageModule),
  },
  {
    path: 'company-introduction',
    loadChildren: () => import('./pages/company-introduction/company-introduction.module').then(m => m.CompanyIntroductionPageModule),
  },
  {
    path: 'report',
    loadChildren: () => import('./pages/report/report.module').then(m => m.ReportPageModule),
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule),
  },
  {
    path: 'post-detail-qna',
    loadChildren: () => import('./pages/post-detail-qna/post-detail-qna.module').then(m => m.PostDetailQnaPageModule),
  },
  {
    path: 'notice-detail',
    loadChildren: () => import('./pages/notice-detail/notice-detail.module').then(m => m.NoticeDetailPageModule),
  },
  {
    path: 'faq-detail',
    loadChildren: () => import('./pages/faq-detail/faq-detail.module').then(m => m.FaqDetailPageModule),
  },
  {
    path: 'qanda-detail',
    loadChildren: () => import('./pages/qanda-detail/qanda-detail.module').then(m => m.QandaDetailPageModule),
  },
  {
    path: 'qanda-write',
    loadChildren: () => import('./pages/qanda-write/qanda-write.module').then(m => m.QandaWritePageModule),
  },
  {
    path: 'profile-edit',
    loadChildren: () => import('./pages/profile-edit/profile-edit.module').then(m => m.ProfileEditPageModule),
  },
  {
    path: 'personal-info',
    loadChildren: () => import('./pages/terms/personal-info/personal-info.module').then(m => m.PersonalInfoPageModule),
  },
  {
    path: 'review-list',
    loadChildren: () => import('./pages/review-list/review-list.module').then(m => m.ReviewListPageModule),
  },
  {
    path: 'review-write',
    loadChildren: () => import('./pages/review-write/review-write.module').then(m => m.ReviewWritePageModule),
  },
  {
    path: 'fixer-profile',
    loadChildren: () => import('./pages/fixer-profile/fixer-profile.module').then(m => m.FixerProfilePageModule),
  },
  {
    path: 'request-form-complete',
    loadChildren: () => import('./pages/request-form-complete/request-form-complete.module').then(m => m.RequestFormCompletePageModule),
  },
  {
    path: 'post-write',
    loadChildren: () => import('./pages/post-write/post-write.module').then(m => m.PostWritePageModule),
  },
  {
    path: 'post-detail-fixer',
    loadChildren: () => import('./pages/post-detail-fixer/post-detail-fixer.module').then(m => m.PostDetailFixerPageModule),
  },
  {
    path: 'report-form',
    loadChildren: () => import('./pages/report-form/report-form.module').then(m => m.ReportFormPageModule),
  },
  {
    path: 'exit',
    loadChildren: () => import('./pages/exit/exit.module').then(m => m.ExitPageModule),
  },
  {
    path: 'inquire-detail',
    loadChildren: () => import('./pages/inquire-detail/inquire-detail.module').then(m => m.InquireDetailPageModule),
  },
  {
    path: 'qna-detail',
    loadChildren: () => import('./pages/qna-detail/qna-detail.module').then(m => m.QnaDetailPageModule),
  },
  {
    path: 'request-form-reject',
    loadChildren: () => import('./pages/request-form-reject/request-form-reject.module').then(m => m.RequestFormRejectPageModule),
  },

  {
    path: 'location',
    loadChildren: () => import('./pages/location/location.module').then(m => m.LocationPageModule),
  },
  {
    path: 'request-form-breakdown-info',
    loadChildren: () =>
      import('./pages/request-form-breakdown-info/request-form-breakdown-info.module').then(m => m.RequestFormBreakdownInfoPageModule),
  },
  {
    path: 'exit-reason',
    loadChildren: () => import('./pages/exit-reason/exit-reason.module').then(m => m.ExitReasonPageModule),
  },
  {
    path: 'atcinfo',
    loadChildren: () => import('./pages/account/atcinfo/atcinfo.module').then(m => m.AtcinfoPageModule),
  },
  { path: '**', redirectTo: '' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
