import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActionSheetController, AlertController, NavController } from '@ionic/angular';
import { of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { DbService } from './db.service';
import { MatDialog } from '@angular/material/dialog';
import { Users } from '../models/users.model';
import { DataService } from './data.service';
import { PushService } from './push.service';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  admin: any;
  user: Users;
  isNewNotification = false;

  constructor(
    private afAuth: AngularFireAuth,
    private db: DbService,
    private alert: AlertService,
    private navCtrl: NavController,
    private alertCtrl: AlertController,
    private actionSheetCtrl: ActionSheetController,
    private dialog: MatDialog,
    private data: DataService,
    private push: PushService
  ) {
    setTimeout(() => {
      this.db.doc$('admin/admin').subscribe(admin => {
        this.admin = admin;
        console.log(this.admin);
      });
      this.afAuth.authState.pipe(switchMap(user => (user ? this.db.doc$(`users/${user.uid}`) : of(null)))).subscribe(user => {
        if (firebase.default.auth().currentUser && firebase.default.auth().currentUser.emailVerified) {
          this.user = user;
        } else {
          if (user && user.loginType.length === 1 && user.loginType[0] === 'email') {
            this.user = null;
          } else {
            this.user = user;
          }
        }
        console.log(this.user);
        if (this.user) {
          this.isNewNotification = this.user.newNotification;
          if (this.user?.uid !== localStorage.getItem('uid')) {
            this.logoutUser();
          }
          if (this.user.suspendSwitch) {
            this.logoutUser().then(() => {
              if (this.alert.isOpenAlert) {
                this.alertCtrl.dismiss();
                this.alert.isOpenAlert = false;
              }
              if (this.alert.isOpenSheet) {
                this.actionSheetCtrl.dismiss();
                this.alert.isOpenSheet = false;
              }
              this.dialog.closeAll();
              this.alert.okBtn(
                '',
                `다수의 회원들로부터 신고가 접수되었습니다. 운영 정책에 따라 계정을 일시적으로 이용하실 수 없으며, 자세한 사항은 ${this.admin.tel} 고객센터로 문의 바랍니다.`
              );
              this.navCtrl.navigateRoot('/tabs/login', { animated: false });
            });
          }
        } else {
          this.isNewNotification = false;
        }
      });
    }, 10);
  }

  async getUser() {
    return await this.afAuth.authState
      .pipe(
        switchMap(user => (user ? this.db.doc$(`users/${user.uid}`) : of(null))),
        take(1)
      )
      .toPromise();
  }

  registerUser(email: string, password: string) {
    return new Promise<any>((resolve, reject) => {
      firebase.default
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(data => {
          localStorage.setItem('uid', data.user.uid);
          resolve(data.user.uid);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  loginUser(email: string, password: string) {
    return new Promise<any>((resolve, reject) => {
      firebase.default
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(data => {
          if (firebase.default.auth().currentUser && firebase.default.auth().currentUser.emailVerified) {
            localStorage.setItem('uid', data.user.uid);
          }
          this.data.changeRepair.next(true);
          resolve(data.user.uid);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  logoutUser() {
    return new Promise((resolve, reject) => {
      const uid = this.user?.uid;
      this.push.getId().then(pushId => {
        if (pushId && this.user && this.user.pushId.includes(pushId.userId)) {
          this.db.updateAt(`users/${uid}`, { pushId: firebase.default.firestore.FieldValue.arrayRemove(pushId.userId) });
        }
      });
      firebase.default
        .auth()
        .signOut()
        .then(() => {
          localStorage.clear();
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  resetPw(email: string) {
    return new Promise((resolve, reject) => {
      firebase.default
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  exitUser(exitReason = ['', '']) {
    return new Promise((resolve, reject) => {
      const user = firebase.default.auth().currentUser;
      this.db
        .updateAt(`users/${user.uid}`, { exitSwitch: true, exitReason })
        .then(() => {
          user
            .delete()
            .then(() => {
              localStorage.clear();
              resolve(true);
            })
            .catch(err => {
              reject(err);
            });
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
