import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { PushPage } from '../push/push.page';

@Component({
  selector: 'app-push-detail',
  templateUrl: './push-detail.page.html',
  styleUrls: ['./push-detail.page.scss'],
})
export class PushDetailPage implements OnInit {
  notification: Notification;

  constructor(private modalController: ModalController, public navc: NavController) {}

  ngOnInit() {
    console.log(this.notification);
  }

  // 모달 닫기
  done() {
    this.modalController.dismiss();
  }
}
