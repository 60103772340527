import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Repair } from 'src/app/models/repair.model';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-request-form-price',
  templateUrl: './request-form-price.component.html',
  styleUrls: ['./request-form-price.component.scss'],
})
export class RequestFormPriceComponent implements OnInit {
  repair: Repair;

  constructor(public data: DataService, private matdialog: MatDialogRef<RequestFormPriceComponent>) {}

  ngOnInit() {}

  // 다이얼로그 닫기
  done() {
    this.matdialog.close();
  }

  // 출동 거리 계산
  getDistance() {
    const meter = this.repair.distance;
    const km = Number(Math.floor(meter / 100) / 10);
    return km > 1 ? `${km}km` : `${meter}m`;
  }

  // 고장 증상
  symptom() {
    const touch = this.repair.symptom.some((symptom: any) => symptom.type === '터치 불가');
    const screen = this.repair.symptom.some((symptom: any) => symptom.type === '화면 이상(멍, 줄)');
    return this.repair.symptom
      .map((symptom: any) => {
        let type = symptom.type;
        let isScreen = false;
        if (symptom.type === '정품 액정' || symptom.type === '카피 액정') {
          isScreen = true;
          if (symptom.instantSwitch !== undefined) {
            if (touch && screen) {
              type += ' / 터치 불가, 화면 이상(멍, 줄)';
            } else if (touch) {
              type += ' / 터치 불가';
            } else if (screen) {
              type += ' / 화면 이상(멍, 줄)';
            } else {
              type += ' / 해당 없음';
            }
          }
        }
        if (symptom.type === '터치 불가' || symptom.type === '화면 이상(멍, 줄)') {
          return null;
        } else {
          return { type, isScreen, cost: symptom.cost };
        }
      })
      .filter(Boolean);
  }

  // 액정 파손 추가 금액
  addCost(): Number {
    const touchCost = this.repair.symptom.filter((symptom: any) => symptom.type === '터치 불가')[0]?.cost || 0;
    const screenCost = this.repair.symptom.filter((symptom: any) => symptom.type === '화면 이상(멍, 줄)')[0]?.cost || 0;
    return touchCost + screenCost;
  }
}
