import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalController } from '@ionic/angular';
import { MbscFormOptions } from '@mobiscroll/angular';
import { Users } from 'src/app/models/users.model';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { DataService } from 'src/app/services/data.service';
import { DbService } from 'src/app/services/db.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ImageDetailComponent } from '../image-detail/image-detail.component';

@Component({
  selector: 'app-review-write',
  templateUrl: './review-write.page.html',
  styleUrls: ['./review-write.page.scss'],
})
export class ReviewWritePage implements OnInit {
  user: Users;
  repairId: string;
  editData: any;

  chkList = [
    {
      value: '친절한 말투와 언행',
      isChk: false,
    },
    {
      value: '자세한 설명',
      isChk: false,
    },
    {
      value: '용모단정한 복장 태도',
      isChk: false,
    },
    {
      value: '신뢰가는 수리 서비스',
      isChk: false,
    },
    {
      value: '빠르고 신속한 출동 수리',
      isChk: false,
    },
    {
      value: '기타(상세 작성)',
      isChk: false,
    },
  ];
  content = '';

  // 별점
  formSettings: MbscFormOptions = {
    theme: 'ios',
    themeVariant: 'light',
  };
  rating = 0;

  constructor(
    private modalController: ModalController,
    private alertService: AlertService,
    private dialog: MatDialog,
    private loader: LoadingService,
    private db: DbService,
    private auth: AuthService,
    private common: CommonService,
    private data: DataService
  ) {}

  ngOnInit() {
    if (this.editData) {
      this.rating = this.editData.rating;

      if (this.editData.content) {
        this.content = this.editData.content;
        this.chkList[5].isChk = true;
      }

      this.chkList.slice(0, 5).forEach(item => {
        item.isChk = this.editData.category.split(', ').includes(item.value);
      });
    }
  }

  // 모달 닫기
  done() {
    this.modalController.dismiss();
  }

  // 작성 완료 버튼 활성화
  require() {
    this.chkList[5].isChk || (this.content = '');
    return this.rating > 0 && this.chkList.map(item => item.isChk).includes(true) && (this.chkList[5].isChk ? this.content.length > 0 : true);
  }

  // 이미지 디테일 모달
  async imageDetailModal(src: string) {
    this.dialog.open(ImageDetailComponent, { data: [src] });
  }

  // 작성완료
  write() {
    const category = this.chkList.reduce((prev: string, curr: any) => {
      return curr.isChk && curr.value !== '기타(상세 작성)' ? (prev ? `${prev}, ${curr.value}` : curr.value) : prev;
    }, '');
    this.alertService
      .cancelOkBtn('', this.editData ? '작성하신 내용으로 리뷰를 수정하시겠어요?' : '작성하신 내용으로 리뷰를 남기시겠어요?')
      .then(async res => {
        if (res) {
          await this.loader.show();
          await this.db.updateAt(`review/${this.editData ? this.editData.id : this.common.generateFilename()}`, {
            dateCreated: new Date().toISOString(),
            anotherUid: this.user.uid,
            uid: this.auth.user.uid,
            rating: this.rating,
            category,
            content: this.content,
            repairId: this.repairId,
          });
          this.loader.hide();
          this.alertService.toast(this.editData ? '리뷰를 수정했어요.' : '리뷰를 남겼어요.');
          this.data.changeRepair.next(true);
          this.done();
        }
      });
  }
}
