// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    // 프로젝트 설정> 내 앱> 웹 앱> SDK 설정 및 구성> 코드 내 firebaseConfig
    apiKey: 'AIzaSyAYjg1G57hCzRdv5NcXRPyLEKrpyBao5dA',
    authDomain: 'fixcare-7e212.firebaseapp.com',
    projectId: 'fixcare-7e212',
    storageBucket: 'fixcare-7e212.appspot.com',
    messagingSenderId: '718687896105',
    appId: '1:718687896105:web:a757eec045674fca795238',
    measurementId: 'G-GJG67QCP3E',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
