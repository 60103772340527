import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Users } from 'src/app/models/users.model';

declare const naver: any;

@Component({
  selector: 'app-location',
  templateUrl: './location.page.html',
  styleUrls: ['./location.page.scss'],
})
export class LocationPage implements OnInit {
  fixer: Users;

  map: any;

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  ionViewWillEnter() {
    setTimeout(() => {
      this.map = new naver.maps.Map('location-detail', {
        center: new naver.maps.LatLng(this.fixer.storeCoordinates.lat, this.fixer.storeCoordinates.lng),
        zoom: 15,
      });
      new naver.maps.Marker({
        map: this.map,
        position: new naver.maps.LatLng(this.fixer.storeCoordinates.lat, this.fixer.storeCoordinates.lng),
        icon: {
          content: '<img src="assets/image/icon-location-map.png" alt="" style="width: 2.75rem; height: 3.5rem" />',
          size: new naver.maps.Size(44, 56),
          origin: new naver.maps.Point(0, 0),
          anchor: new naver.maps.Point(22, 56),
        },
      });
    }, 10);
  }

  // 모달 닫기
  done() {
    this.modalController.dismiss();
  }
}
