import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Repair } from 'src/app/models/repair.model';
import { Users } from 'src/app/models/users.model';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/services/data.service';
import { DbService } from 'src/app/services/db.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-request-form-reject',
  templateUrl: './request-form-reject.page.html',
  styleUrls: ['./request-form-reject.page.scss'],
})
export class RequestFormRejectPage implements OnInit {
  repair: Repair;

  refusalReason: string;

  constructor(
    private modalController: ModalController,
    private alertService: AlertService,
    private data: DataService,
    private db: DbService,
    private loader: LoadingService
  ) {}

  ngOnInit() {}

  // 모달 닫기
  done() {
    this.modalController.dismiss();
  }

  // 거절하기
  reject() {
    // 출동 수리
    this.alertService
      .cancelOkBtn('header', '응답을 거절하시면 수리 요청이 취소되며, 해당 요청서는 사라지게 돼요. 픽서의 응답을 거절하시겠어요?', '응답 거절')
      .then(async res => {
        if (res) {
          await this.loader.show();
          await Promise.all([
            this.db.updateAt(`repair/${this.repair.id}`, { status: '응답 거절', refusalReason: this.refusalReason, fixerSymptom: [] }),
            this.db.updateAt(`users/${(this.repair.fixerId as Users).uid}`, { repairId: '' }),
          ]);
          this.loader.hide();
          this.alertService.toast('응답을 거절했어요.');
          this.data.changeRepair.next(true);
          this.done();
        }
      });
  }
}
