import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { ActionSheetButton, ActionSheetController, ModalController } from '@ionic/angular';
import { MbscFormOptions } from '@mobiscroll/angular';
import { of, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';
import { DataService } from 'src/app/services/data.service';
import { DbService, leftJoinDocument } from 'src/app/services/db.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ChatPage } from '../chat/chat.page';
import { ImageDetailComponent } from '../image-detail/image-detail.component';
import { LocationPage } from '../location/location.page';
import { ReviewWritePage } from '../review-write/review-write.page';
import * as firebase from 'firebase';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-fixer-profile',
  templateUrl: './fixer-profile.page.html',
  styleUrls: ['./fixer-profile.page.scss'],
})
export class FixerProfilePage implements OnInit {
  isComplete = false;

  repairId: string;
  fixerId: string;
  fixer: any;
  repairHistory: number;
  reviewDataAll = [];
  reviewData = [];
  review = [];
  reviewEndCount = 10;
  newChat = false;

  slideOpts = {
    speed: 400,
    zoom: false,
    spaceBetween: 0,
    slidesPerView: 1,
    initialSlide: 0,
    loop: false,
  };
  formSettings: MbscFormOptions = {
    theme: 'ios',
    themeVariant: 'light',
  };

  sub: Subscription;

  constructor(
    public auth: AuthService,
    public data: DataService,
    private modalController: ModalController,
    private alertService: AlertService,
    private actionSheetController: ActionSheetController,
    private dialog: MatDialog,
    private db: DbService,
    private afs: AngularFirestore,
    private loader: LoadingService,
    private common: CommonService,
    private chat: ChatService
  ) {}

  ngOnInit() {
    this.getReviewData();
  }

  ngOnDestroy() {
    this.sub && this.sub.unsubscribe();
  }

  // 리뷰 데이터 불러오기
  async getReviewData() {
    await this.loader.show();
    await Promise.all([
      this.data.getRepairHistory(this.fixerId),
      this.db.doc$(`users/${this.fixerId}`).pipe(take(1)).toPromise(),
      this.db
        .collection$('review', (ref: any) => ref.where('anotherUid', '==', this.fixerId).orderBy('dateCreated', 'desc'))
        .pipe(take(1))
        .toPromise(),
    ]).then(async ([repairHistory, fixer, reviewData]) => {
      this.repairHistory = repairHistory.length;
      this.fixer = fixer;
      this.reviewDataAll = reviewData;

      const myReview = reviewData.filter(({ uid }) => uid === this.auth.user.uid);
      const anotherReview = reviewData.filter(({ uid, id }) => uid !== this.auth.user.uid && !this.auth.user.reportReview.includes(id));
      this.reviewData = myReview.concat(anotherReview);

      if (this.repairId) {
        this.sub = this.db
          .collection$('chat', (ref: any) => ref.where('repairId', '==', this.repairId).where('uid', 'array-contains', this.auth.user.uid))
          .subscribe(data => {
            if (data.length > 0) {
              const message = data[0].message.filter(
                (msg: any) => msg.uid !== this.auth.user.uid && (msg.uid !== '안내' || (msg.uid === '안내' && msg.type === '고객'))
              );
              const badge = message.length - data[0][this.auth.user.uid].readIndex;
              this.newChat = badge > 0;
            } else {
              this.newChat = false;
            }
          });
      }

      this.review = [];
      await this.getReview(0, this.reviewEndCount);
      console.log(this.review);
    });
    this.isComplete = true;
    this.loader.hide();
  }

  // 리뷰 불러오기
  async getReview(startNum: number, endNum: number) {
    this.review.push(
      ...(await Promise.all(
        this.reviewData.slice(startNum, endNum).map(async review => {
          return await of(review)
            .pipe(leftJoinDocument(this.afs, 'uid', 'users'), leftJoinDocument(this.afs, 'repairId', 'repair'), take(1))
            .toPromise();
        })
      ))
    );
  }

  // 모달 닫기
  done() {
    this.modalController.dismiss();
  }

  // 이미지 디테일 모달
  async imageDetailModal(src: string) {
    this.dialog.open(ImageDetailComponent, { data: [src] });
  }

  // 평균 별점
  avgRating() {
    const avgRating = this.reviewDataAll.length && this.reviewDataAll.reduce((prev, curr) => prev + curr.rating, 0) / this.reviewDataAll.length;
    return [avgRating > 0 ? avgRating.toFixed(1) : avgRating, avgRating];
  }

  // 별점
  rating(rating: number) {
    return rating.toString().length === 1 ? `${rating}.0` : rating;
  }

  // 위치 모달
  async locationModal() {
    this.done();
    const modal = await this.modalController.create({
      component: LocationPage,
      backdropDismiss: false,
      componentProps: { fixer: this.fixer },
    });
    await modal.present();
  }

  // 인피니트 스크롤
  async loadData(ev: any) {
    await this.getReview(this.reviewEndCount, this.reviewEndCount + 10);
    this.reviewEndCount += 10;
    ev.target.complete();
  }

  // 리뷰 더보기
  async reviewMore(uid: string, index: number, reviewId: string) {
    this.alertService.isOpenSheet = true;
    const buttons: Array<ActionSheetButton> = [
      {
        text: '취소',
        role: 'cancel',
        handler: () => {
          this.alertService.isOpenSheet = false;
        },
      },
    ];
    if (this.auth.user.uid === uid) {
      buttons.unshift(
        {
          text: '리뷰 수정',
          handler: async () => {
            this.alertService.isOpenSheet = false;
            this.done();
            const modal = await this.modalController.create({
              component: ReviewWritePage,
              cssClass: 'review-modal',
              backdropDismiss: false,
              componentProps: { user: this.fixer, repairId: this.reviewData[index].repairId, editData: this.reviewData[index] },
            });
            await modal.present();
          },
        },
        {
          text: '리뷰 삭제',
          handler: () => {
            this.alertService.isOpenSheet = false;
            this.alertService.cancelOkBtn('', '리뷰를 삭제하시겠어요?').then(async res => {
              if (res) {
                this.isComplete = false;
                await this.loader.show();
                await this.db.delete(`review/${reviewId}`);
                this.data.changeRepair.next(true);
                this.loader.hide();
                await this.getReviewData();
                this.alertService.toast('리뷰를 삭제했어요.');
              }
            });
          },
        }
      );
    } else {
      buttons.unshift({
        text: '신고 및 차단하기',
        handler: () => {
          this.alertService.isOpenSheet = false;
          this.alertService
            .cancelOkBtn('confirm', '신고시 서비스 운영원칙에 따라 조치되며, 해당 리뷰가 나에게 노출되지 않아요. 신고하시겠어요?')
            .then(async res => {
              if (res) {
                this.isComplete = false;
                await this.loader.show();
                await Promise.all([
                  this.db.updateAt(`users/${this.auth.user.uid}`, { reportReview: firebase.default.firestore.FieldValue.arrayUnion(reviewId) }),
                  this.db.updateAt(`report/${this.common.generateFilename()}`, {
                    dateCreated: new Date().toISOString(),
                    reportId: reviewId,
                    uid: this.auth.user.uid,
                    category: '',
                    content: '',
                    type: 'review',
                  }),
                ]);
                this.loader.hide();
                await this.getReviewData();
                this.alertService.toast('리뷰를 신고했어요.');
              }
            });
        },
      });
    }
    const actionSheet = await this.actionSheetController.create({
      cssClass: 'actionsheet',
      buttons,
    });
    await actionSheet.present();
  }

  // 채팅 모달
  async chatModal() {
    await this.loader.show();
    const chatId = this.repairId ? await this.chat.createChatRoom(this.fixerId, this.repairId) : null;
    this.loader.hide();
    this.done();
    const modal = await this.modalController.create({
      component: ChatPage,
      cssClass: 'chat-modal',
      componentProps: { chatId },
    });
    await modal.present();
  }
}
