import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss'],
})
export class ServiceComponent implements OnInit {
  constructor(public auth: AuthService, private dialog: MatDialogRef<ServiceComponent>) {}

  ngOnInit() {}

  done() {
    this.dialog.close();
  }
}
