import { Injectable } from '@angular/core';
import { DbService } from './db.service';
import { LoadingService } from './loading.service';
import { take } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { ModalController, NavController } from '@ionic/angular';
import { AtcinfoPage } from '../pages/account/atcinfo/atcinfo.page';
import { AuthService } from './auth.service';
import { PushService } from './push.service';
import { NgOneTapService } from 'ng-google-one-tap';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import jwtDecode from 'jwt-decode';
import * as firebase from 'firebase';

declare const AppleID: any;
declare const naver: any;
declare const Kakao: any;

@Injectable({
  providedIn: 'root',
})
export class SocialService {
  loginWithNaverId = new naver.LoginWithNaverId({
    clientId: 'wMhEgEv6OuHRgY3NFHco',
    callbackUrl: 'https://fixcare.kr/tabs/login',
    isPopup: true,
  });
  naverAccessToken: string;

  constructor(
    private loader: LoadingService,
    private db: DbService,
    private alert: AlertService,
    private modalCtrl: ModalController,
    private auth: AuthService,
    private push: PushService,
    private navCtrl: NavController,
    private ngOneTap: NgOneTapService,
    private http: HttpClient
  ) {
    this.initAppleLogin();
    this.initNaverLogin();
    Kakao.init('c602c802e427a576f549c2eff15529e9');
  }

  // 추가 정보 모달
  private async joinModal(email: string, type: 'google' | 'apple' | 'naver' | 'kakao', credential?: any) {
    const modal = await this.modalCtrl.create({
      component: AtcinfoPage,
      backdropDismiss: false,
      componentProps: { email, type, credential },
    });
    await modal.present();
  }

  // 애플 로그인
  private initAppleLogin() {
    AppleID.auth.init({
      clientId: 'com.knc.fixcare',
      scope: 'email',
      redirectURI: 'https://fixcare.kr/tabs/login',
      state: 'signin',
      usePopup: true,
    });

    // 로그인 성공
    document.addEventListener('AppleIDSignInOnSuccess', async ({ detail: { authorization } }: any) => {
      try {
        const { id_token } = authorization;
        const { email } = jwtDecode<any>(id_token);
        const credential = new firebase.default.auth.OAuthProvider('apple.com').credential(id_token);

        if (email === 'fixcareadmin@gmail.com') {
          this.alert.okBtn('', '사용할 수 없는 이메일입니다.');
          throw '사용할 수 없는 이메일입니다.';
        }

        const [user] = await this.db
          .collection$('users', (ref: any) => ref.where('email', '==', email).where('exitSwitch', '==', false))
          .pipe(take(1))
          .toPromise();

        if (user) {
          if (!user.suspendSwitch) {
            if (user.loginType.includes('apple')) {
              await firebase.default.auth().signInWithCredential(credential);
              localStorage.setItem('uid', user.uid);
              await this.auth.getUser();

              this.alert.toast('로그인 했어요.');
              this.navCtrl.navigateForward('/tabs/home', { animated: false });

              this.push.getId().then(data => {
                if (data && !user.fixerSwitch && !user.pushId.includes(data.userId)) {
                  user.pushId.push(data.userId);
                  this.db.updateAt(`users/${user.uid}`, { pushId: user.pushId });
                }
              });
            } else {
              if (user.loginType.includes('email')) {
                this.alert
                  .okCancelInputBtn('해당 이메일로 가입된 계정이<br />존재합니다. 연동을 원하시면<br />비밀번호를 입력해 주세요.')
                  .then(async pw => {
                    if (pw !== false) {
                      await this.loader.show();
                      await this.auth
                        .loginUser(email, pw)
                        .then(async () => {
                          localStorage.setItem('uid', user.uid);
                          await this.auth.getUser();
                          await firebase.default.auth().currentUser.linkWithCredential(credential);

                          this.alert.toast('로그인 했어요.');
                          this.navCtrl.navigateForward('/tabs/home', { animated: false });

                          this.db.updateAt(`users/${user.uid}`, { loginType: firebase.default.firestore.FieldValue.arrayUnion('apple') });
                          this.push.getId().then(data => {
                            if (data && !user.fixerSwitch && !user.pushId.includes(data.userId)) {
                              user.pushId.push(data.userId);
                              this.db.updateAt(`users/${user.uid}`, { pushId: user.pushId });
                            }
                          });
                        })
                        .catch(() => {
                          this.alert.toast('비밀번호가 올바르지 않습니다.');
                        });
                      this.loader.hide();
                    }
                  });
              } else {
                const loginType = user.loginType[0] === 'naver' ? '네이버' : user.loginType[0] === 'kakao' ? '카카오' : '구글';
                this.alert.okBtn('', `해당 이메일은 ${loginType} 계정으로<br />가입되어 있습니다.<br />${loginType}로 로그인해 주세요.`);
              }
            }
          } else {
            this.alert.okBtn(
              '',
              `다수의 회원들로부터 신고가 접수되었습니다. 운영 정책에 따라 계정을 일시적으로 이용하실 수 없으며, 자세한 사항은 ${this.auth.admin.tel} 고객센터로 문의 바랍니다.`
            );
          }
        } else {
          this.joinModal(email, 'apple', credential);
        }
      } catch (error) {
        console.log(error.error || error);
      }
      this.loader.hide();
    });

    // 로그인 실패
    document.addEventListener('AppleIDSignInOnFailure', ({ detail: { error } }: any) => {
      console.log(error);
      this.loader.hide();
    });
  }

  // 네이버 로그인
  private async initNaverLogin() {
    this.loginWithNaverId.init();
    this.naverAccessToken = this.loginWithNaverId.callbackHandler.parameterMap?.access_token;

    if (this.naverAccessToken) {
      await this.loader.show();
      try {
        const email = await this.http
          .post<{ response: { email: string } }>('https://us-central1-fixcare-7e212.cloudfunctions.net/socialLogin/getNaverEmail', {
            naverAccessToken: this.naverAccessToken,
          })
          .toPromise()
          .then(({ response: { email } }) => email);

        if (email === 'fixcareadmin@gmail.com') {
          this.alert.okBtn('', '사용할 수 없는 이메일입니다.');
          throw '사용할 수 없는 이메일입니다.';
        }

        const [user] = await this.db
          .collection$('users', (ref: any) => ref.where('email', '==', email).where('exitSwitch', '==', false))
          .pipe(take(1))
          .toPromise();

        if (user) {
          if (!user.suspendSwitch) {
            if (user.loginType.includes('naver')) {
              const firebaseToken = await this.linkSocialLogin(user.uid, 'NAVER');
              await firebase.default.auth().signInWithCustomToken(firebaseToken);
              localStorage.setItem('uid', user.uid);
              await this.auth.getUser();

              this.alert.toast('로그인 했어요.');
              this.navCtrl.navigateForward('/tabs/home', { animated: false });

              this.push.getId().then(data => {
                if (data && !user.fixerSwitch && !user.pushId.includes(data.userId)) {
                  user.pushId.push(data.userId);
                  this.db.updateAt(`users/${user.uid}`, { pushId: user.pushId });
                }
              });
            } else {
              if (user.loginType.includes('email')) {
                this.alert
                  .okCancelInputBtn('해당 이메일로 가입된 계정이<br />존재합니다. 연동을 원하시면<br />비밀번호를 입력해 주세요.')
                  .then(async pw => {
                    if (pw !== false) {
                      await this.loader.show();
                      await this.auth
                        .loginUser(email, pw)
                        .then(async () => {
                          localStorage.setItem('uid', user.uid);
                          await this.auth.getUser();

                          this.alert.toast('로그인 했어요.');
                          this.navCtrl.navigateForward('/tabs/home', { animated: false });

                          this.db.updateAt(`users/${user.uid}`, { loginType: firebase.default.firestore.FieldValue.arrayUnion('naver') });
                          this.push.getId().then(data => {
                            if (data && !user.fixerSwitch && !user.pushId.includes(data.userId)) {
                              user.pushId.push(data.userId);
                              this.db.updateAt(`users/${user.uid}`, { pushId: user.pushId });
                            }
                          });
                        })
                        .catch(() => {
                          this.alert.toast('비밀번호가 올바르지 않습니다.');
                        });
                      this.loader.hide();
                    }
                  });
              } else {
                const loginType = user.loginType[0] === 'kakao' ? '카카오' : user.loginType[0] === 'google' ? '구글' : '애플';
                this.alert.okBtn('', `해당 이메일은 ${loginType} 계정으로<br />가입되어 있습니다.<br />${loginType}로 로그인해 주세요.`);
              }
            }
          } else {
            this.alert.okBtn(
              '',
              `다수의 회원들로부터 신고가 접수되었습니다. 운영 정책에 따라 계정을 일시적으로 이용하실 수 없으며, 자세한 사항은 ${this.auth.admin.tel} 고객센터로 문의 바랍니다.`
            );
          }
        } else {
          this.joinModal(email, 'naver');
        }
      } catch (error) {
        console.log(error.error || error);
        this.navCtrl.navigateRoot('/tabs/login');
      }
      this.loader.hide();
    }
  }

  /** 구글 로그인 */
  async googleLogin() {
    const googleLoginSub = new Subscription();

    await this.loader.show();
    try {
      this.ngOneTap.signOut();
      this.ngOneTap.tapInitialize();

      let idToken = '';
      this.ngOneTap.oneTapCredentialResponse
        .pipe(take(1))
        .toPromise()
        .then(credentialRes => {
          idToken = credentialRes.credential;
        });

      await new Promise<boolean | string>((resolve, reject) => {
        const promtMomentSub = this.ngOneTap.promtMoment.subscribe(notification => {
          if (!notification.isDisplayed()) {
            if (String(notification.getDismissedReason()) === 'credential_returned') {
              resolve(true);
            } else {
              reject('구글 로그인에 실패하였습니다.');
            }
          }
        });
        googleLoginSub.add(promtMomentSub);
      });

      const credential = firebase.default.auth.GoogleAuthProvider.credential(idToken);
      const { email } = jwtDecode<any>(idToken);

      if (email === 'fixcareadmin@gmail.com') {
        this.alert.okBtn('', '사용할 수 없는 이메일입니다.');
        throw '사용할 수 없는 이메일입니다.';
      }

      const [user] = await this.db
        .collection$('users', (ref: any) => ref.where('email', '==', email).where('exitSwitch', '==', false))
        .pipe(take(1))
        .toPromise();

      if (user) {
        if (!user.suspendSwitch) {
          if (user.loginType.includes('google')) {
            await firebase.default.auth().signInWithCredential(credential);
            localStorage.setItem('uid', user.uid);
            await this.auth.getUser();

            this.alert.toast('로그인 했어요.');
            this.navCtrl.navigateForward('/tabs/home', { animated: false });

            this.push.getId().then(data => {
              if (data && !user.fixerSwitch && !user.pushId.includes(data.userId)) {
                user.pushId.push(data.userId);
                this.db.updateAt(`users/${user.uid}`, { pushId: user.pushId });
              }
            });
          } else {
            if (user.loginType.includes('email')) {
              this.alert
                .okCancelInputBtn('해당 이메일로 가입된 계정이<br />존재합니다. 연동을 원하시면<br />비밀번호를 입력해 주세요.')
                .then(async pw => {
                  if (pw !== false) {
                    await this.loader.show();
                    await this.auth
                      .loginUser(email, pw)
                      .then(async () => {
                        localStorage.setItem('uid', user.uid);
                        await this.auth.getUser();
                        await firebase.default.auth().currentUser.linkWithCredential(credential);

                        this.alert.toast('로그인 했어요.');
                        this.navCtrl.navigateForward('/tabs/home', { animated: false });

                        this.db.updateAt(`users/${user.uid}`, { loginType: firebase.default.firestore.FieldValue.arrayUnion('google') });
                        this.push.getId().then(data => {
                          if (data && !user.fixerSwitch && !user.pushId.includes(data.userId)) {
                            user.pushId.push(data.userId);
                            this.db.updateAt(`users/${user.uid}`, { pushId: user.pushId });
                          }
                        });
                      })
                      .catch(() => {
                        this.alert.toast('비밀번호가 올바르지 않습니다.');
                      });
                    this.loader.hide();
                  }
                });
            } else {
              const loginType = user.loginType[0] === 'naver' ? '네이버' : user.loginType[0] === 'kakao' ? '카카오' : '애플';
              this.alert.okBtn('', `해당 이메일은 ${loginType} 계정으로<br />가입되어 있습니다.<br />${loginType}로 로그인해 주세요.`);
            }
          }
        } else {
          this.alert.okBtn(
            '',
            `다수의 회원들로부터 신고가 접수되었습니다. 운영 정책에 따라 계정을 일시적으로 이용하실 수 없으며, 자세한 사항은 ${this.auth.admin.tel} 고객센터로 문의 바랍니다.`
          );
        }
      } else {
        this.joinModal(email, 'google', credential);
      }
    } catch (error) {
      console.log(error.error || error);
    }
    this.loader.hide();

    googleLoginSub.unsubscribe();
  }

  /** 애플 로그인 */
  async appleLogin() {
    await this.loader.show();
    try {
      document.getElementById('appleid-signin').click();
    } catch {
      this.alert.okBtn('', '페이지 새로고침 후 다시 시도해 주세요.');
      this.loader.hide();
    }
  }

  /** 네이버 로그인 */
  async naverLogin() {
    await this.loader.show();
    try {
      this.loginWithNaverId.reprompt();
    } catch {
      this.alert.okBtn('', '페이지 새로고침 후 다시 시도해 주세요.');
      this.loader.hide();
    }
  }

  /** 카카오 로그인 */
  kakaoLogin() {
    Kakao.Auth.loginForm({
      success: async () => {
        if (!this.auth.user) {
          this.navCtrl.navigateRoot('/tabs/login');

          await this.loader.show();
          try {
            const {
              kakao_account: { email },
            } = await Kakao.API.request({ url: '/v2/user/me' });

            if (email === 'fixcareadmin@gmail.com') {
              this.alert.okBtn('ok-alert', '사용할 수 없는 이메일입니다.');
              throw '사용할 수 없는 이메일입니다.';
            }

            if (email) {
              const [user] = await this.db
                .collection$('users', (ref: any) => ref.where('email', '==', email).where('exitSwitch', '==', false))
                .pipe(take(1))
                .toPromise();

              if (user) {
                if (!user.suspendSwitch) {
                  if (user.loginType.includes('kakao')) {
                    const firebaseToken = await this.linkSocialLogin(user.uid, 'KAKAO');
                    await firebase.default.auth().signInWithCustomToken(firebaseToken);
                    localStorage.setItem('uid', user.uid);
                    await this.auth.getUser();

                    this.alert.toast('로그인 했어요.');
                    this.navCtrl.navigateForward('/tabs/home', { animated: false });

                    this.push.getId().then(data => {
                      if (data && !user.fixerSwitch && !user.pushId.includes(data.userId)) {
                        user.pushId.push(data.userId);
                        this.db.updateAt(`users/${user.uid}`, { pushId: user.pushId });
                      }
                    });
                  } else {
                    if (user.loginType.includes('email')) {
                      this.alert
                        .okCancelInputBtn('해당 이메일로 가입된 계정이<br />존재합니다. 연동을 원하시면<br />비밀번호를 입력해 주세요.')
                        .then(async pw => {
                          if (pw !== false) {
                            await this.loader.show();
                            await this.auth
                              .loginUser(email, pw)
                              .then(async () => {
                                localStorage.setItem('uid', user.uid);
                                await this.auth.getUser();

                                this.alert.toast('로그인 했어요.');
                                this.navCtrl.navigateForward('/tabs/home', { animated: false });

                                this.db.updateAt(`users/${user.uid}`, { loginType: firebase.default.firestore.FieldValue.arrayUnion('kakao') });
                                this.push.getId().then(data => {
                                  if (data && !user.fixerSwitch && !user.pushId.includes(data.userId)) {
                                    user.pushId.push(data.userId);
                                    this.db.updateAt(`users/${user.uid}`, { pushId: user.pushId });
                                  }
                                });
                              })
                              .catch(() => {
                                this.alert.toast('비밀번호가 올바르지 않습니다.');
                              });
                            this.loader.hide();
                          }
                        });
                    } else {
                      const loginType = user.loginType[0] === 'naver' ? '네이버' : user.loginType[0] === 'google' ? '구글' : '애플';
                      this.alert.okBtn('', `해당 이메일은 ${loginType} 계정으로<br />가입되어 있습니다.<br />${loginType}로 로그인해 주세요.`);
                    }
                  }
                } else {
                  this.alert.okBtn(
                    '',
                    `다수의 회원들로부터 신고가 접수되었습니다. 운영 정책에 따라 계정을 일시적으로 이용하실 수 없으며, 자세한 사항은 ${this.auth.admin.tel} 고객센터로 문의 바랍니다.`
                  );
                }
              } else {
                this.joinModal(email, 'kakao');
              }
            } else {
              await Kakao.API.request({ url: '/v1/user/unlink' });
              this.alert.okBtn('', '이메일이 제공되지 않았을 경우<br />카카오 로그인이 불가능합니다.');
            }
          } catch (error) {
            console.log(error.error || error);
          }
          this.loader.hide();
        }
      },
      fail: (error: any) => {
        console.log(error);
      },
    });
  }

  /** 이메일 로그인에 소셜 로그인 연동 또는 회원가입 */
  async linkSocialLogin(uid: string, provider: 'NAVER' | 'KAKAO', isSignUp = false, email = null) {
    return await this.http
      .post<{ firebaseToken: string }>('https://us-central1-fixcare-7e212.cloudfunctions.net/socialLogin', { uid, provider, isSignUp, email })
      .toPromise()
      .then(({ firebaseToken }) => firebaseToken);
  }
}
